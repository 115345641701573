import { httpApi } from '@app/api/http.api';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { notificationController } from '@app/controllers/notificationController';
import { TList } from '@app/types/generalTypes';
import { TRecommendationView, TRecommendationViewForm } from '@app/types/recommendation';
import { TRecommendationForm } from '@app/components/apps/manage-recommendations/RecommendationForm';
import { AxiosResponse } from 'axios';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { TPrerequisiteOption } from '@app/types/prerequisiteOption';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import { InputNumberProps } from '@app/components/common/inputs/InputNumber/InputNumber';

const getPrerequisiteOptions = async (recommendationId?: number): Promise<TPrerequisiteOption[]> => {
  const queryString = recommendationId ? `recommendationId=${recommendationId}` : '';
  const response = await httpApi.get(`/recommendations/getPrerequisiteOptions?${queryString}`);
  return response?.data;
};

export const useGetPrerequisiteOptions = (recommendationId?: number) => {
  return useQuery({
    queryFn: () => getPrerequisiteOptions(recommendationId),
    queryKey: `get-prerequisite-options-${recommendationId ?? 0}`,
  });
};

const validateRecommendation = async (recommendationId: number) => {
  const response = await httpApi.post(`/recommendations/validate/${recommendationId}`);
  return response?.data;
};

export const useValidateRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: validateRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('recommendationExtended-odata');
      notificationController.success({ message: `Finished Validating the recommendation` });
    },
  });
};

const validateAllRecommendations = async () => {
  const response = await httpApi.post('/recommendations/validateAll');
  return response?.data;
};

export const useValidateAllRecommendations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: validateAllRecommendations,
    onSuccess() {
      queryClient.invalidateQueries('recommendationExtended-odata');
      notificationController.success({ message: `Finished validating all recommendations` });
    },
  });
};

const getRecommentaionViews = async (): Promise<TList<TRecommendationView>> => {
  const { data } = await httpApi.get<TList<TRecommendationView>>('/odata/RecommendationsView?$filter=status eq 1');
  return data;
};

export const useGetRecommendationViews = () => useQuery('recommendation-views', getRecommentaionViews);

const createRecommendationView = async (form: TRecommendationViewForm) => {
  const response = await httpApi.post<any>('/recommendations-view/create', form);

  if (response?.status === 201) {
    notificationController.success({
      message: `Created ${form.name}`,
    });
  }

  return response?.data;
};

export const getRecommendation = async (id: number): Promise<TAccountRecommendationExtended> => {
  const { data } = await httpApi.get<TList<TAccountRecommendationExtended>>(
    `odata/accountRecommendationExtended?$filter=recommendationId eq ${id}`,
  );
  return data.items[0];
};

export const getRecommendations = async (ids: number[]): Promise<TAccountRecommendationExtended[]> => {
  const filterQuery = ids.map((id) => `recommendationId eq ${id}`).join(' or ');

  const { data } = await httpApi.get<TList<TAccountRecommendationExtended>>(
    `odata/accountRecommendationExtended?$filter=${filterQuery}`,
  );
  return data.items;
};

export const useCreateRecommendationView = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createRecommendationView,
    onSuccess() {
      queryClient.invalidateQueries('recommendation-views');
    },
  });
};

const updateRecommendationView = async (payload: {
  viewId: string;
  form: TRecommendationViewForm;
  isDelete?: boolean;
}) => {
  const { viewId, form, isDelete } = payload;
  const response = await httpApi.patch<any>(`/recommendations-view/update/${viewId}`, form);

  if (response?.status === 200) {
    notificationController.success({
      message: isDelete ? `View Deleted` : `View Updated`,
    });
  }

  return response?.data;
};

export const useUpdateRecommendationView = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateRecommendationView,
    onSuccess() {
      queryClient.invalidateQueries('recommendation-views');
    },
  });
};

export const useSetExemptRecommendation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: setExemptRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('accountRecommendationExtended-odata');
    },
  });
};

export interface ExemptRecommendationRequest {
  accountRecommendationIds: string[];
  exempt: boolean;
  reason?: string;
}

const setExemptRecommendation = async (request: ExemptRecommendationRequest) => {
  const response = await httpApi.post(`/account-recommendation/setExempt`, request);
  if (response?.status === 201) {
    notificationController.success({
      message: `Recommendations ${request.exempt ? 'Exempted' : 'Unexempted'}`,
    });
  }
  return response;
};

const createRecommendation = async (data: TRecommendationForm) => {
  try {
    const response = await httpApi.post<any>('/recommendations', { ...data, queryType: 1 });
    if (response?.status === 201) {
      notificationController.success({
        message: `Recommendation Created`,
      });
    }
    return response;
  } catch (error) {
    notificationController.error({
      message: `Failed to create recommendation`,
    });
    throw error;
  }
};

export const useCreateRecommendation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('recommendationExtended-odata');
    },
  });
};

const updateRecommendation = async (payload: {
  id: number;
  data: TRecommendationForm;
}): Promise<AxiosResponse<any, any>> => {
  const { id, data } = payload;
  const response = await httpApi.patch<any>(`/recommendations/${id}`, data);
  if (response?.status === 200) {
    notificationController.success({
      message: `Recommendation Updated`,
    });
  }
  return response;
};

export const useUpdateRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('recommendationExtended-odata');
    },
  });
};

export const getAccountRecommendationExtended = async (id: string): Promise<TAccountRecommendationExtended> => {
  const response = await httpApi.get(`/odata/AccountRecommendationExtended?$filter=(recommendation_id eq '${id}')`);
  return response?.data?.items?.at(0);
};

const publishRecommendation = async (recommendationId: number): Promise<AxiosResponse<any, any>> => {
  try {
    const response = await httpApi.post<any>(`/recommendations/${recommendationId}/publish`);
    if (response?.status === 201) {
      notificationController.success({
        message: `Recommendation Published`,
      });
    }
    return response;
  } catch (error) {
    notificationController.error({
      message: `Failed to publish recommendation`,
    });
    throw error;
  }
};

export const usePublishRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: publishRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('recommendationExtended-odata');
    },
  });
};

export type SaveAndPublishRecommendationInfo = {
  data: TRecommendationForm;
  isEdit: boolean;
  id?: number;
};

const saveAndPublishRecommendation = async (
  info: SaveAndPublishRecommendationInfo,
): Promise<AxiosResponse<any, any>> => {
  const { data, isEdit, id } = info;
  const response = isEdit
    ? await httpApi.patch<any>(`/recommendations/${id}`, data)
    : await httpApi.post<any>(`/recommendations`, { ...data, queryType: 1 });

  if (response?.status === 201 || response?.status === 200) {
    const recommendationId = id || response.data.id;
    const publishResponse = await httpApi.post<any>(`/recommendations/${recommendationId}/publish`);
    if (publishResponse?.status === 201) {
      notificationController.success({
        message: `Recommendation ${isEdit ? 'updated' : 'created'} and Published`,
      });
    }
    return publishResponse;
  }
  return response;
};

export const useSaveAndPublishRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: saveAndPublishRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('recommendationExtended-odata');
    },
  });
};

const disableRecommendation = async (recommendationId: number): Promise<AxiosResponse<any, any>> => {
  const response = await httpApi.post<any>(`/recommendations/${recommendationId}/disable`);
  if (response?.status === 201) {
    notificationController.success({
      message: `Recommendation Disabled`,
    });
  }
  return response;
};

export const useDisableRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: disableRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('recommendationExtended-odata');
    },
  });
};

const setCompliantRecommendation = async (payload: {
  accountRecommendationIds: string[];
  compliant: boolean;
  notes?: string;
}): Promise<AxiosResponse<any, any>> => {
  const response = await httpApi.post<any>(`/account-recommendation/setCompliant`, payload);
  if (response?.status === 201) {
    notificationController.success({
      message: `Recommendations Updated`,
    });
  }
  return response;
};

export const useSetCompliantRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setCompliantRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('accountRecommendationExtended-odata');
    },
  });
};

const assignRecommendation = async (request: {
  recommendationIds: number[];
  assignedToId?: number;
  notes?: string;
}) => {
  if (request.assignedToId && request.assignedToId < 0) delete request.assignedToId;

  const response = await httpApi.post(`/account-recommendation/assign`, request);
  if (response?.status === 201) {
    notificationController.success({
      message: !request.assignedToId ? 'Recommendations Unassigned' : 'Recommendations Assigned',
    });
  }
  return response;
};

export const useAssignRecommendation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: assignRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('accountRecommendationExtended-odata');
    },
  });
};

const deleteRecommendation = async (recommendationId: number) => {
  const response = await httpApi.delete<any>(`/recommendations/${recommendationId}`);
  if (response?.status === 200 || response?.status === 204) {
    notificationController.success({
      message: `Recommendation Deleted`,
    });
  }
  return response?.data;
};

export const useDeleteRecommendation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteRecommendation,
    onSuccess() {
      queryClient.invalidateQueries('recommendationExtended-odata');
    },
  });
};

const getAccountRecommendationHistory = async (
  accountRecommendationId: string,
): Promise<TRecommendationEventType[]> => {
  const response = await httpApi.get(`/account-recommendation/${accountRecommendationId}/history`);
  return response.data;
};

export const useGetACcountRecommendationHistory = (accountRecommendationId: string) => {
  return useQuery({
    queryFn: () => getAccountRecommendationHistory(accountRecommendationId),
    queryKey: `account-recommendation-history-${accountRecommendationId}`,
  });
};

const addRecommendationComment = async ({
  accountRecommendationId,
  comment,
}: {
  accountRecommendationId: string;
  comment: string;
}) => {
  const response = await httpApi.post<any>(`/account-recommendation/${accountRecommendationId}/comment`, { comment });
  return response;
};

export const useAddRecommendationComment = (accountRecommendationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addRecommendationComment,
    onSuccess() {
      queryClient.invalidateQueries(`account-recommendation-history-${accountRecommendationId}`);
    },
  });
};

const updateRecommendationComment = async ({
  accountRecommendationId,
  commentId,
  comment,
}: {
  accountRecommendationId: string;
  commentId: number;
  comment: string;
}) => {
  const response = await httpApi.patch<any>(`/account-recommendation/${accountRecommendationId}/comment`, {
    commentId,
    comment,
  });
  return response;
};

export const useUpdateRecommendationComment = (accountRecommendationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateRecommendationComment,
    onSuccess() {
      queryClient.invalidateQueries(`account-recommendation-history-${accountRecommendationId}`);
    },
  });
};
