import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Editor } from '@tiptap/react';
import { FaAlignLeft, FaAlignCenter, FaAlignRight, FaAlignJustify, FaChevronDown } from 'react-icons/fa';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const MenuButton = styled.button<{ showDropdown: boolean }>`
  background-color: ${(props) => (props.showDropdown ? 'lightgray' : 'transparent')};
  border: none;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  gap: 6px;

  &:hover {
    background-color: lightgray;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
`;

const DropdownMenu = styled.div<{ showDropdown?: boolean }>`
  display: ${(props) => (props.showDropdown ? 'flex' : 'none')};
  flex-direction: row;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 6px;
  z-index: 1;
  width: fit-content;
  gap: 2px;
`;

const TextAlignButton = styled.button<{ isActive?: boolean }>`
  background-color: ${(props) => (props.isActive ? 'lightgray' : 'transparent')};
  padding: 6px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;

  &:hover {
    background-color: lightgray;
  }
`;

type Alignment = 'left' | 'center' | 'right' | 'justify';

const TextAlignDropdown = ({ editor }: { editor: Editor }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeAlignment, setActiveAlignment] = useState<Alignment>('left');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const alignmentIcons: Record<Alignment, JSX.Element> = {
    left: <FaAlignLeft />,
    center: <FaAlignCenter />,
    right: <FaAlignRight />,
    justify: <FaAlignJustify />,
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  const setAlignment = (alignment: Alignment) => {
    editor.chain().focus().setTextAlign(alignment).run();
    setActiveAlignment(alignment);
    setShowDropdown(false);
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <MenuButton
        title="Align & indent"
        type="button"
        onClick={() => setShowDropdown(!showDropdown)}
        showDropdown={showDropdown}
      >
        {alignmentIcons[activeAlignment]}
        <FaChevronDown size={10} />
      </MenuButton>
      <DropdownMenu showDropdown={showDropdown} role="menu">
        <TextAlignButton
          title="Left align"
          type="button"
          onClick={() => setAlignment('left')}
          isActive={activeAlignment === 'left'}
        >
          {alignmentIcons['left']}
        </TextAlignButton>
        <TextAlignButton
          title="Center align"
          type="button"
          onClick={() => setAlignment('center')}
          isActive={activeAlignment === 'center'}
        >
          {alignmentIcons['center']}
        </TextAlignButton>
        <TextAlignButton
          title="Right align"
          type="button"
          onClick={() => setAlignment('right')}
          isActive={activeAlignment === 'right'}
        >
          {alignmentIcons['right']}
        </TextAlignButton>
        <TextAlignButton
          title="Justify"
          type="button"
          onClick={() => setAlignment('justify')}
          isActive={activeAlignment === 'justify'}
        >
          {alignmentIcons['justify']}
        </TextAlignButton>
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default TextAlignDropdown;
