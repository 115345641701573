import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BASE_COLORS, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`;

export const TabsList = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 0.75rem;
  background-color: ${BASE_COLORS.white};
  border: 1px solid ${BASE_COLORS.ashgray};
  padding: 0.25rem;
  max-width: max-content;
`;

export const TabsContent = styled.div`
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: ${BASE_COLORS.white};
  border: 1px solid ${BASE_COLORS.ashgray};
`;

type TabProps = {
  $isActive: boolean;
};

export const Tab = styled(BaseButton)`
  border-radius: 0.625rem;
  height: 2.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 2rem;
  width: 9.5rem;
  background-color: ${({ $isActive }: TabProps) => ($isActive ? '#d5eedd' : '#fff')};
  color: ${({ $isActive }: TabProps) => ($isActive ? '#2c9e52' : `#ACACAC`)};
  border: none;
  &:hover {
    background-color: ${({ $isActive }: TabProps) => ($isActive ? '#258544' : '#fff')};
    color: ${({ $isActive }: TabProps) => ($isActive ? '#A3DAB5' : `#2C9E52`)};
  }
  &:focus {
    background-color: ${({ $isActive }: TabProps) => ($isActive ? '#d5eedd' : '#fff')};
    color: ${({ $isActive }: TabProps) => ($isActive ? '#2c9e52' : `#ACACAC`)};
  }
  &:disabled {
    background-color: #fff;
    color: #acacac;

    &:hover {
      background-color: #fff;
      color: #acacac;
    }
  }
  font-weight: ${({ $isActive }: TabProps) => ($isActive ? `${FONT_WEIGHT.semibold}` : `${FONT_WEIGHT.medium}`)};
`;
