import React, { useState } from 'react';

import { ActionDropdown, ActionDropdownProps } from '@app/components/tables/AntdTableWrapper/components/ActionDropdown';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { useReInviteUser } from '@app/api/user.api';
import StatusBadge from '@app/components/apps/alerts/StatusBadge';
import { TUsers } from '@app/types/users';
import ActionConfirmationModal from '@app/components/apps/manage-users/ActionConfirmationModal';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import CreateAlertButton from '@app/components/apps/recommendations/CreateAlertButton';
import { useTranslation } from 'react-i18next';
import InviteVendorsModal from '@app/components/apps/manage-vendors/InviteVendorsModal';
import { getEnumValue } from '@app/services/enum.service';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';

const tableCols: TDataColumns<TUsers> = [
  {
    title: 'Email',
    dataIndex: 'email',
    allowFiltering: true,
    type: 'text',
    showSortDirections: true,
    width: 300,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    allowFiltering: true,
    type: 'enum',
    enumValuesKey: 'UserRole',
    showSortDirections: true,
  },
  {
    title: 'Date Joined',
    dataIndex: 'creationDate',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
  },
  {
    title: 'Last Login Date',
    dataIndex: 'lastLoginDate',
    allowFiltering: true,
    type: 'date',
    showSortDirections: true,
    width: 150,
  },
];

const ManageVendorsPage: React.FC = () => {
  const { t } = useTranslation();

  const [isInviteVendorsModalOpen, setIsInviteVendorsModalOpen] = useState(false);
  const { mutateAsync: reinviteUser, isLoading: isReinviteUserLoading } = useReInviteUser();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<TUsers | null>(null);
  const [action, setAction] = useState<'block' | 'delete' | 're-invite' | null>(null);
  const [message, setMessage] = useState('');

  const status = (status: 'Active' | 'Pending' | 'Blocked') => {
    if (status === 'Active') {
      return 'enabled';
    }

    if (status === 'Pending') {
      return 'pending';
    }

    return 'disabled';
  };

  const items: ActionDropdownProps<TUsers>['items'] = [
    // {
    //   label: 'Block',
    //   key: 'block',
    //   onClick: (record) => {
    //     setSelectedUser(record);
    //     setAction('block');
    //     setMessage('Are you sure you want to block this user?');
    //     setIsModalOpen(true);
    //   },
    // },

    // {
    //   label: 'Delete',
    //   key: 'delete',
    //   onClick: (record) => {
    //     setSelectedUser(record);
    //     setAction('delete');
    //     setMessage('Are you sure you want to delete this user?');
    //     setIsModalOpen(true);
    //   },
    // },
    {
      label: 'Re-invite user',
      key: 'reInviteUser',
      onClick: (record) => {
        // reinviteUser(record.email);
        // should be reinvite vendors?
      },
      show: (rec) => (rec.status as any) === 'Pending',
    },
  ];

  const actionItems: TActionItems<TUsers>[] = [
    {
      label: 'Re-invite user',
      key: 'reInviteUser',
      multiSelect: false,
      onClick: (item) => {
        // reinviteUser(record.email);
        // should be reinvite vendors?
      },
      show: (item) => (item.status as any) === 'Pending',
    },
  ];

  const manageUsersCols: TDataColumns<TUsers> = [
    ...tableCols,
    {
      title: 'Status',
      dataIndex: 'status',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'UserStatus',
      showSortDirections: true,
      render: (val, record) => <StatusBadge type={status(record.status as any)}>{record.status}</StatusBadge>,
    },
    // {
    //   title: 'Actions',
    //   key: 'action',
    //   align: 'center' as const,
    //   width: 100,
    //   render: (_, record) => {
    //     return <ActionDropdown items={items} record={record} />;
    //   },
    // },
  ];

  const dataTableProps = useDataTable<TUsers>({
    model: 'user',
    columns: manageUsersCols,
    stickyHeader: true,
    searchableColumns: ['email'],
    defaultOrder: {
      column: 'email',
      order: 'asc',
    },
    constantFilter: `(role eq ${getEnumValue('UserRole', 'Vendor') || 4})`,
    actionItems,
  });

  const handleModalClose = () => {
    setSelectedUser(null);
    setAction(null);
    setMessage('');
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    // if (action === 'block') {
    //   if (selectedUser) {
    //     await blockUser(selectedUser?.id);
    //     handleModalClose();
    //   }
    //   return;
    // }

    // if (action === 'delete') {
    //   if (selectedUser) {
    //     await deleteUser(selectedUser?.id);
    //     handleModalClose();
    //   }
    //   return;
    // }

    if (action === 're-invite') {
      return;
    }
  };

  return (
    <>
      <PageTitle>Manage Vendors</PageTitle>
      <ComponentHeader
        title={t('common.manageVendors')}
        actionButtons={
          <>
            <CreateAlertButton title="Invite Vendors" onPress={() => setIsInviteVendorsModalOpen(true)} />
          </>
        }
      />
      <DataTable {...dataTableProps} />
      <InviteVendorsModal
        open={isInviteVendorsModalOpen}
        onCancel={() => setIsInviteVendorsModalOpen(false)}
        onInviteSuccess={dataTableProps.refetch}
      />

      <ActionConfirmationModal
        open={isModalOpen}
        onOk={handleConfirm}
        onCancel={handleModalClose}
        message={message}
        // isLoading={isBlocking || isDeleting}
      />
    </>
  );
};

export default ManageVendorsPage;
