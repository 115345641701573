import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Editor } from '@tiptap/react';
import { RxLineHeight } from 'react-icons/rx';

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const IconWrapper = styled.button<{ showDropdown: boolean }>`
  background-color: ${(props) => (props.showDropdown ? 'lightgray' : 'transparent')};
  border: none;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  gap: 6px;

  &:hover {
    background-color: lightgray;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
`;

const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #ffffff;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 6px;
`;

const DropdownItem = styled.div<{ isActive?: boolean }>`
  background-color: ${(props) => (props.isActive ? 'lightgray' : 'transparent')};
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const LineSpacingDropdown = ({ editor }: { editor: Editor }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSpacing, setSelectedSpacing] = useState('1.0');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const lineSpacings = [
    { label: 'Single', value: '1.0' },
    { label: '1.15', value: '1.15' },
    { label: '1.5', value: '1.5' },
    { label: 'Double', value: '2.0' },
  ];

  //* Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  //* Update selected spacing when selection changes
  useEffect(() => {
    const handleSelectionUpdate = () => {
      const lineHeight = editor.getAttributes('paragraph').lineHeight || '1.0';
      setSelectedSpacing(lineHeight);
    };

    editor.on('selectionUpdate', handleSelectionUpdate);
    editor.on('transaction', handleSelectionUpdate);

    return () => {
      editor.off('selectionUpdate', handleSelectionUpdate);
      editor.off('transaction', handleSelectionUpdate);
    };
  }, [editor]);

  const handleSelect = (spacing: string) => {
    setSelectedSpacing(spacing);
    setShowDropdown(false);

    if (editor.can().setLineHeight(spacing)) {
      editor.chain().focus().setLineHeight(spacing).run();
    }
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <IconWrapper
        title="Line & paragraph spacing"
        type="button"
        onClick={() => setShowDropdown(!showDropdown)}
        showDropdown={showDropdown}
      >
        <RxLineHeight />
      </IconWrapper>
      <DropdownContent isOpen={showDropdown}>
        {lineSpacings.map(({ label, value }) => (
          <DropdownItem isActive={selectedSpacing === value} key={value} onClick={() => handleSelect(value)}>
            {label}
          </DropdownItem>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default LineSpacingDropdown;
