import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import { withLoading } from '@app/hocs/withLoading.hoc';
import { useGetRoutes } from '@app/hooks/useGetRoutes';
import { authRoutes } from './routes';

const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));

// error 404 page
const Error404 = withLoading(Error404Page);

// layouts
const AuthLayoutFallback = withLoading(AuthLayout);
const MainLayoutFallback = withLoading(MainLayout);

export const AppRouter: React.FC = () => {
  const allowedRoutes = useGetRoutes();

  const router = createBrowserRouter([
    {
      element: <MainLayoutFallback />,
      path: '/',
      errorElement: <Error404 />,
      children: allowedRoutes,
    },
    {
      element: <AuthLayoutFallback />,
      path: '/auth',
      errorElement: <Error404 />,
      children: authRoutes,
    },
  ]);

  return <RouterProvider router={router} />;
};
