import { format, parseISO } from 'date-fns';

import {
  HorizontalAlignedContainer,
  SpaceGrower,
  VerticalContainer,
} from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';

interface IExemptionChanges {
  event: TRecommendationEventType;
  title?: string;
  showTimeOnly?: boolean;
}

const ExemptionChanges = ({ event, title, showTimeOnly }: IExemptionChanges) => {
  const { change_type, user, comment, date } = event;

  const exemptionText = change_type === 'improvement' ? 'Un-Exempted' : 'Exempted';
  const exemptionMessage = `${user} marked this recommendation as ${exemptionText.toLowerCase()}${
    !comment || comment?.length === 0 ? '.' : ' with notes:'
  }`;

  return (
    <VerticalContainer style={{ gap: '4px', marginBottom: '12px' }}>
      <HorizontalAlignedContainer>
        <span
          style={{ fontSize: '16px', fontWeight: '600', color: change_type === 'improvement' ? 'orange' : '#404040' }}
        >{`${title ? title : 'Recommendation'} Was ${exemptionText}`}</span>
        <SpaceGrower />
        <span
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
            color: 'gray',
            marginRight: '12px',
          }}
        >{`${!showTimeOnly ? `${format(parseISO(date), 'dd MMM, yyyy')} at` : ''} ${format(
          parseISO(date),
          "HH:mm aaaaa'm'",
        )}`}</span>
      </HorizontalAlignedContainer>
      <span style={{ fontSize: '14px' }}>{exemptionMessage}</span>
      {!!comment && comment?.length !== 0 && <HtmlEditor initialValue={comment} readOnly />}
    </VerticalContainer>
  );
};

export { ExemptionChanges };
