import { ReactElement } from 'react';
import { HorizontalAlignedContainer, SpaceGrower } from '../BaseLayout/BaseLayout.styled';
import * as S from './Tabs.styled';

interface ITabsProps {
  tabContent: { title: string; disabled: boolean; content?: ReactElement }[];
  activeTab?: number;
  setActiveTab?: (index: number) => void;
}

export default function Tabs({ tabContent, activeTab, setActiveTab }: ITabsProps) {
  return (
    <S.TabsContainer>
      <HorizontalAlignedContainer>
        <S.TabsList>
          {tabContent?.map((tab, index) => (
            <S.Tab
              key={index}
              $isActive={activeTab === index ? true : false}
              onClick={() => setActiveTab?.(index)}
              disabled={tab.disabled}
              style={{ minWidth: '200px' }}
            >
              {tab.title}
            </S.Tab>
          ))}
        </S.TabsList>
        <SpaceGrower />
      </HorizontalAlignedContainer>
      {activeTab != undefined && <div>{tabContent[activeTab].content}</div>}
    </S.TabsContainer>
  );
}
