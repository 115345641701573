import { Editor } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import styled from 'styled-components';

const FontSizeMenuDropdown = styled.div`
  position: relative;
`;

const IconWrapper = styled.button<{ showDropdown: boolean }>`
  background-color: ${(props) => (props.showDropdown ? 'lightgray' : 'transparent')};
  border: none;
  border-radius: 6px;
  padding: 0px 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  gap: 6px;

  &:hover {
    background-color: lightgray;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
`;

const FontSizeOptionsContainer = styled.div<{ showDropdown?: boolean }>`
  display: ${(props) => (props.showDropdown ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100%;
  right: 0;
  width: auto;
  max-height: 200px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const FontSizeOption = styled.div<{ isActive?: boolean }>`
  padding: 4px 11px;
  cursor: pointer;
  font-size: 16px;
  color: black;
  background-color: ${(props) => (props.isActive ? 'lightgray' : 'transparent')};

  &:hover {
    background-color: lightgray;
  }
`;

const FontSizePicker = ({ editor }: { editor: Editor }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedSize, setSelectedSize] = useState('16px');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    editor.chain().focus().setFontSize(selectedSize).run();
  }, [editor, selectedSize]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleFontSizeChange = (size: string) => {
    setSelectedSize(size);
    if (editor.can().setFontSize(size)) {
      editor.chain().focus().setFontSize(size).run();
    }
    setShowDropdown(false);
  };

  return (
    <FontSizeMenuDropdown ref={dropdownRef}>
      <IconWrapper
        title="Font size"
        type="button"
        onClick={() => setShowDropdown(!showDropdown)}
        showDropdown={showDropdown}
      >
        {selectedSize}
        <FaChevronDown size={10} />
      </IconWrapper>
      <FontSizeOptionsContainer showDropdown={showDropdown}>
        {Array.from({ length: 20 }, (_, i) => 16 + i * 2).map((size) => (
          <FontSizeOption
            key={size}
            isActive={selectedSize === `${size}px`}
            onClick={() => handleFontSizeChange(`${size}px`)}
          >
            {size}
          </FontSizeOption>
        ))}
      </FontSizeOptionsContainer>
    </FontSizeMenuDropdown>
  );
};

export default FontSizePicker;
