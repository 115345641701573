import useToggle from '@app/hooks/useToggle';
import { TMessageTemplate } from '@app/types/messaging';
import { TMessageTemplateForm } from './MessagingForm';

interface IUseMessagingFormProps {
  onOk?: (data: TMessageTemplateForm) => void;
  onCancel?: () => void;
  messageTemplate?: TMessageTemplate;
}

const useMessagingForm = (props?: IUseMessagingFormProps) => {
  const [isModalOpen, toggle, setIsModalOpen] = useToggle();

  const handleOk = (data: TMessageTemplateForm) => {
    props?.onOk?.(data);
  };

  const handleCancel = () => {
    props?.onCancel?.();
    setIsModalOpen(false);
  };

  return {
    open: isModalOpen,
    onOk: handleOk,
    onCancel: handleCancel,
    toggle,
    messageTemplate: props?.messageTemplate,
  };
};

export default useMessagingForm;
