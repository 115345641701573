import { Table } from 'antd';
import { format, parseISO } from 'date-fns';

import { TRemediatedRecommendation } from '@app/types/dashboardData';
import styled from 'styled-components';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';

const StyledTable = styled(Table)`
  .ant-table-thead .ant-table-cell {
    font-size: 14px;
  }

  .ant-table-row .ant-table-cell {
    font-size: 0.75rem;
    font-weight: 500;
    color: #272727;
    line-height: 20px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
`;

interface IRemediatedTableProps {
  data: TRemediatedRecommendation[];
}

export function RemediatedTable({ data }: IRemediatedTableProps) {
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      className: '.custom-ant-table-thead',
      render: (_: any, record: any) => {
        return <>{format(parseISO(record.date), 'dd/MM/yyyy')}</>;
      },
      width: '10%',
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      width: '12%',
    },
    {
      title: 'Recommendation',
      dataIndex: 'findings',
      key: 'findings',
      width: '78%',
    },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (record: any) => <a onClick={() => navigate(`/recommendations/${record.id}`)}>View</a>,
    //   width: '10%',
    //   align: 'center' as const,
    // },
  ];

  return (
    <StyledTable
      dataSource={data}
      columns={columns}
      pagination={false}
      size="middle"
      onRow={(record: any, _) => ({
        style: { cursor: 'pointer' },
        onClick: (event) => {
          navigate(`/recommendations/${record.id}`);
        },
      })}
    />
  );
}
