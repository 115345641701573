import { Modal, ModalFuncProps } from 'antd';
import { endOfDay, format, startOfDay } from 'date-fns';
import styled from 'styled-components';

import { useGetModelItems } from '@app/api/table.api';
import { TRecommendationEventType, TRecommendationHistory } from '@app/types/recommendationHistory';
import { EventItem } from '../recommendations/RecommendationDetailsModal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #272727;
`;

const Value = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: #272727;
`;

interface IManageAccountsProps extends ModalFuncProps {
  changeLogDate: string;
  eventsText: string;
}

export default function EventDetailsModal({ changeLogDate, eventsText, ...modalProps }: IManageAccountsProps) {
  const { data } = useGetModelItems<TRecommendationHistory>({
    model: 'accountRecommendationHistory',
    queryParams: `$filter=date ge ${startOfDay(changeLogDate).toISOString()} and date le ${endOfDay(
      changeLogDate,
    ).toISOString()}&orderby=date desc`,
  });

  const handleCancel = () => {
    modalProps.onCancel?.();
  };

  return (
    <Modal
      title={`Events Details - ${format(changeLogDate, 'yyyy-MM-dd')}`}
      style={{
        minWidth: '90vw',
      }}
      {...modalProps}
      destroyOnClose={true}
      footer={null}
      onCancel={handleCancel}
    >
      <Container>
        <DetailContainer>
          <Label>Summary</Label>
          <Value>{eventsText}</Value>
        </DetailContainer>
        <DetailContainer>
          <Label>Details</Label>
          <div>
            {data?.items.map((item, index) => {
              const event: TRecommendationEventType = {
                id: index,
                change_type: item.changeType as any,
                comment: '',
                date: item.date,
                user: item.changedBy as any,
                event_type: item.changeField as any,
                change_details: item.changeDetails,
                changedDescription: item.changeDescription,
                assigned_to: item.assignedTo as any,
                snapshot_type: item.snapshotType as any,
              };

              return (
                <EventItem
                  key={item.recommendationId}
                  eventType={event}
                  accountRecommendationId={''}
                  recommendationValidationMethod={parseInt(item.validationMethod)}
                  commentReadOnly={true}
                  showTimeOnly={true}
                  title={`Recommendation "${item.findings}"`}
                />
              );
            })}
          </div>
        </DetailContainer>
      </Container>
    </Modal>
  );
}
