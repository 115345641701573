import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { FilterIcon, TrashIcon } from '@app/components/icons';
import { CustomDropdown } from '@app/components/tables/AntdTableWrapper/components/CustomDropdown';
import Divider from 'antd/lib/divider';
import Dropdown from 'antd/lib/dropdown/dropdown';
import styled from 'styled-components';
import { MenuItemType } from 'antd/lib/menu/hooks/useItems';
import { ChangeEvent } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e9eaeb;
  background-color: #fff;
  padding: 6px 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  gap: 4px;
`;

const IconContainer = styled.div`
  background-color: #f8f8f8;
  padding: 6px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 94px;
`;

const FilterChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e9eaeb;
  border-radius: 8px;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  margin-right: 6px;
  margin-bottom: 10px;
`;

const FilterChipColumnText = styled.span`
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
`;

const FilterChipCloseButton = styled(CloseOutlined)`
  margin-left: 5px;
  cursor: pointer;
`;

const ClearFilterIcon = styled(FilterChip)<{ isActive: boolean }>`
  align-self: flex-start;
  margin-top: 4px;
  font-weight: 600;
  color: ${(props) => (props.isActive ? '#272727' : '#acacac')};
  flex-shrink: 0;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
  gap: 4px;
  ${(props) => (props.isActive ? 'border: 1px solid #272727;' : '')}
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 4px;
`;

interface IItemSelectProps<T extends MenuItemType> {
  items: T[];
  selectedItems: T[];
  isDropdownOpen: boolean;
  searchItemValue: string;
  isLoading?: boolean;
  isSearching?: boolean;
  onItemRemove: (index: number) => void;
  onItemClicked: (key: string) => void;
  onClearSelection: () => void;
  toggleDropdown: (open: boolean) => void;
  onSearchItem: (e: ChangeEvent<HTMLInputElement>) => void;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  onEndReached?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

function ItemSelect<T extends MenuItemType>({
  items,
  selectedItems,
  isDropdownOpen,
  searchItemValue,
  isLoading,
  isSearching,
  onClearSelection,
  onItemRemove,
  onItemClicked,
  toggleDropdown,
  onSearchItem,
  onScroll,
  onEndReached,
}: IItemSelectProps<T>) {
  return (
    <Container>
      <FilterContainer style={{ flexGrow: 1, paddingBottom: '0px' }}>
        <IconContainer style={{ alignSelf: 'flex-start', marginTop: '8px' }}>
          <FilterIcon />
        </IconContainer>

        <Divider type="vertical" style={{ alignSelf: 'flex-start', marginTop: '16px' }} />

        <FilterChipsContainer>
          <FilterChipsContainer>
            {selectedItems.map((item, index) => (
              <FilterChip key={index}>
                <FilterChipColumnText>{item.label}</FilterChipColumnText>
                <FilterChipCloseButton role="button" onClick={() => onItemRemove?.(index)} />
              </FilterChip>
            ))}
          </FilterChipsContainer>
          <Dropdown
            menu={{
              items,
              onClick: (info) => onItemClicked?.(info.key),
            }}
            trigger={['click']}
            arrow
            open={isDropdownOpen}
            onOpenChange={toggleDropdown}
            dropdownRender={(menu) => (
              <CustomDropdown
                menu={menu}
                inputValue={searchItemValue}
                onChange={onSearchItem}
                onScroll={onScroll}
                onEndReached={onEndReached}
                isLoading={isLoading}
                isSearching={isSearching}
              />
            )}
          >
            <Text role="button" style={{ flexShrink: 0 }}>
              Search...
            </Text>
          </Dropdown>
        </FilterChipsContainer>

        <div style={{ flexGrow: 1 }} />

        <ClearFilterIcon role="button" isActive={selectedItems.length > 0} onClick={() => onClearSelection?.()}>
          <TrashIcon fill={selectedItems.length > 0 ? '#D81C2E' : '#ACACAC'} />
          Clear Filters
        </ClearFilterIcon>
      </FilterContainer>
    </Container>
  );
}

export default ItemSelect;
