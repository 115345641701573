import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useTranslation } from 'react-i18next';
import { Error } from '@app/components/Error/Error';
import NotAllowed from '@app/assets/images/not-allowed.svg';

const NotAllowedPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.clientError')}</PageTitle>
      <Error img={NotAllowed} msg={t('errorNotAllowed.notAllowed')} width={600} />
    </>
  );
};

export default NotAllowedPage;
