import { useTranslation } from 'react-i18next';

import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Link, useParams } from 'react-router-dom';
import { TInsight } from '@app/types/insight';
import InsightDetailsModal from '@app/components/apps/insights/InsightDetailsModal';
import { ActionDropdown, ActionDropdownProps } from '@app/components/tables/AntdTableWrapper/components/ActionDropdown';
import { useEffect, useState } from 'react';
import { getInsight } from '@app/api/insights.api';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { BulbOnIcon } from '@app/components/icons';

export default function Insights() {
  const { t } = useTranslation();
  const [selectedInsight, setSelectedInsight] = useState<TInsight | undefined>(undefined);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const { insightId } = useParams();

  async function initialize() {
    if (insightId) {
      const parsedInsightId = parseInt(insightId);
      const insight = await getInsight(parsedInsightId);
      await setSelectedInsight(insight);
      await setIsDetailsModalOpen(true);
    }
  }

  useEffect(() => {
    initialize();
  }, []);

  const items: ActionDropdownProps<TInsight>['items'] = [
    {
      label: 'View Details',
      key: 'insight-view-details',
      onClick: (item) => {
        setSelectedInsight(item);
        setIsDetailsModalOpen(true);
      },
    },
  ];

  // const actionItems: TActionItems<TInsight>[] = [
  //   {
  //     label: 'View Details',
  //     key: 'view-details',
  //     multiSelect: false,
  //     onClick: (item) => {
  //       setSelectedInsight(item);
  //       setIsDetailsModalOpen(true);
  //     },
  //   },
  // ];

  const tableCols: TDataColumns<TInsight> = [
    {
      title: 'Insight Summary',
      dataIndex: 'title',
      type: 'text',
      showSortDirections: false,
      width: 400,
      render: (_, record) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <BulbOnIcon />
            {record.title}
          </div>
        );
      },
    },
    {
      title: '',
      align: 'right' as const,
      key: 'action',
      width: 100,

      render: (_, record) => {
        return (
          <a
            onClick={() => {
              setSelectedInsight(record);
              setIsDetailsModalOpen(true);
            }}
          >
            View Details
          </a>
        );
      },
    },
  ];

  const dataTableProps = useDataTable<TInsight>({
    model: 'insight',
    columns: tableCols,
    searchableColumns: ['title'],
    constantFilter: '(status eq 1)',
    defaultOrder: {
      column: 'priority',
      order: 'asc',
    },
    showHeader: false,
    onRowClick: (data, index) => {
      setSelectedInsight(data);
      setIsDetailsModalOpen(true);
    },
  });

  return (
    <>
      <PageTitle>{t('common.insights')}</PageTitle>
      <ComponentHeader
        title={t('common.insights')}
        subTitle="Our team of experts has assembled valuable insights specifically tailored to your environment, providing relevant information and practical advice to enhance your security."
        subTitleFullWidth={true}
      />
      <DataTable {...dataTableProps} canSelectRows={false} />
      <InsightDetailsModal
        open={isDetailsModalOpen}
        insight={selectedInsight}
        onCancel={() => setIsDetailsModalOpen(false)}
      />
    </>
  );
}
