import { Modal, ModalFuncProps, Select, Spin } from 'antd';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import styled from 'styled-components';
import Tabs from '@app/components/common/Tabs/Tabs';

import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TRecommendation } from '@app/types/recommendation';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useEffect, useMemo, useState } from 'react';
import { getEnumLabel, getEnumValue } from '@app/services/enum.service';
import { useTranslation } from 'react-i18next';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { useGetPrerequisiteOptions } from '@app/api/recommendation.api';
import { CheckBox } from '@app/components/header/components/searchDropdown/searchOverlay/SearchFilter/SearchFilter.styles';
import { parseDbEnumArray } from '@app/components/common/common';
import { capitalize } from '@app/utils/utils';
import OverviewFields from './manage-recommendation-tabs/GeneralFields';
import RequirementsFields from './manage-recommendation-tabs/RequirementsFields';
import RemediationStepsFields from './manage-recommendation-tabs/RemediationStepsFields';

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 35%;
  flex-shrink: 0;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const FieldArea = styled.textarea`
  flex-basis: 75%;
  background-color: transparent;
  border: 1px solid #acacac;
  border-radius: 10px;
  padding: 12px;
  outline: none;
  resize: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export type TOption = {
  value: number;
  label: string;
};

export type TRecommendationForm = {
  findings: string;
  description: string;
  remediation: string;
  severity: number;
  // category: number;
  easeOfFix: number;
  estimatedWork: number;
  productId: number;
  correctiveAction: string;
  query: string;
  status: number;
  operatingSystem: number;
  licenses: number[];
  reference: string;
  scopes: string | number[];
  tenantWide: boolean;
  userImpact: string;
  prerequisiteDescription: string;
  prerequisiteReference: string;
  prerequisites: string[];
  validationMethod: number;
};

interface IRecommendationProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  recommendation?: TRecommendation;
  error?: string | null;
  onSaveAndPublish: (data: TRecommendationForm) => void;
  onDisableRecommendation: (data: TRecommendationForm) => void;
}

export default function RecommendationForm({
  isEdit,
  isLoading,
  recommendation,
  error,
  onSaveAndPublish,
  onDisableRecommendation,
  ...modalProps
}: IRecommendationProps) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const products = useAppSelector((state) => state.app.products);
  const licenses = useAppSelector((state) => state.app.licenses);

  const { data: prerequisiteData, isLoading: prerequisiteLoading } = useGetPrerequisiteOptions(recommendation?.id);
  const [prerequisiteOptions, initialPrerequisiteOptions] = useMemo(() => {
    if (prerequisiteData) {
      const options = prerequisiteData.map((entry) => ({
        value: entry.isRecommendation ? `rec-${entry.id}` : `st-${entry.id}`,
        label: entry.label,
      }));
      const initialValue = prerequisiteData
        .filter((entry) => entry.related)
        .map((entry) => (entry.isRecommendation ? `rec-${entry.id}` : `st-${entry.id}`));
      return [options, initialValue];
    }
    return [[], []];
  }, [prerequisiteData]);

  const productOptions = useMemo(() => {
    return products?.map((product) => ({
      value: product.id,
      label: product.displayName,
    }));
  }, [products]) as TOption[];

  const licenseOptions = useMemo(() => {
    return licenses?.map((product) => ({
      value: product.id,
      label: product.displayName,
    }));
  }, [licenses]) as TOption[];

  const severityOptions = (
    useAppSelector((state) => (state.app.appValues as any)?.RecommendationSeverity) as TOption[]
  )?.map((opt) => ({
    ...opt,
    label: t(`appValues.RecommendationSeverity.${opt.label}`),
  }));
  // const categoryOptions = (
  //   useAppSelector((state) => (state.app.appValues as any)?.RecommendationCategory) as TOption[]
  // )?.map((opt) => ({
  //   ...opt,
  //   label: t(`appValues.RecommendationCategory.${opt.label}`),
  // }));
  const operatingSystemOptions = (
    useAppSelector((state) => (state.app.appValues as any)?.OperatingSystem) as TOption[]
  )?.map((opt) => ({
    ...opt,
    label: t(`appValues.OperatingSystem.${opt.label}`),
  }));
  const easeOfFixOptions = useAppSelector(
    (state) => (state.app.appValues as any)?.RecommendationEaseOfFix,
  ) as TOption[];
  const estimatedWorkOptions = useAppSelector(
    (state) => (state.app.appValues as any)?.RecommendationEstimatedWork,
  ) as TOption[];
  const recommendationScopeOptions = useAppSelector(
    (state) => (state.app.appValues as any)?.RecommendationScope,
  ) as TOption[];
  const recommendationValidationMethodOptions = useAppSelector(
    (state) => (state.app.appValues as any)?.RecommendationValidationMethod,
  ) as TOption[];

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<TRecommendationForm>({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (modalProps.open && !!recommendation) {
      reset({
        description: recommendation.description,
        findings: recommendation.findings,
        productId: products?.find((product) => product.name === recommendation.productName)?.id || 1,
        remediation: recommendation.remediation,
        correctiveAction: recommendation.correctiveAction,
        query: recommendation.query,
        severity: getEnumValue('RecommendationSeverity', recommendation.severity.toString().replaceAll(' ', '')),
        // category: getEnumValue('RecommendationCategory', recommendation.category.toString().replaceAll(' ', '')),
        estimatedWork: getEnumValue(
          'RecommendationEstimatedWork',
          recommendation.estimatedWork.toString().replaceAll(' ', ''),
        ),
        easeOfFix: getEnumValue('RecommendationEaseOfFix', recommendation.easeOfFix.toString().replaceAll(' ', '')),
        status: getEnumValue('RecommendationStatus', (recommendation.status as any) == 1 ? 'Enabled' : 'Disabled'),
        operatingSystem:
          getEnumValue('OperatingSystem', capitalize(recommendation.operatingSystem?.toString().replaceAll(' ', ''))) ||
          0,
        licenses: recommendation.licenses?.[0] === null ? [] : recommendation.licenses,
        tenantWide: recommendation.tenantWide.toString().toLowerCase() === 'true',
        reference: recommendation.reference,
        scopes: parseDbEnumArray(recommendation.scopes),
        userImpact: recommendation.userImpact,
        prerequisiteDescription: recommendation.prerequisiteDescription || '',
        prerequisiteReference: recommendation.prerequisiteReference,
        prerequisites: initialPrerequisiteOptions,
        validationMethod: getEnumValue(
          'RecommendationValidationMethod',
          (recommendation.validationMethod as any) === 'Auto' || !recommendation.validationMethod
            ? 'Automatic'
            : 'Manual',
        ),
      });
    } else if (modalProps.open && !recommendation) {
      reset({
        description: '',
        findings: '',
        productId: 1,
        remediation: '',
        correctiveAction: '',
        query: '',
        severity: 1,
        // category: 1,
        estimatedWork: 1,
        easeOfFix: 1,
        status: 1,
        operatingSystem: 1,
        licenses: [],
        scopes: [],
        reference: '',
        tenantWide: false,
        userImpact: '',
        prerequisiteDescription: '',
        prerequisiteReference: '',
        prerequisites: [],
        validationMethod: 1,
      });
    }
  }, [modalProps.open, recommendation, prerequisiteLoading]);

  const onSubmit: SubmitHandler<TRecommendationForm> = (data) => {
    const parsedPrerequisites = !data.prerequisites
      ? []
      : data.prerequisites.map((pr) => {
          const [typeStr, idStr] = pr.split('-');
          return {
            isRecommendation: typeStr === 'rec',
            id: parseInt(idStr),
          };
        });
    modalProps.onOk?.({ ...data, prerequisites: parsedPrerequisites });
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  const tabContent = [
    {
      title: 'General Information',
      content: (
        <OverviewFields
          productOptions={productOptions}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          severityOptions={severityOptions}
          operatingSystemOptions={operatingSystemOptions}
          estimatedWorkOptions={estimatedWorkOptions}
          recommendationScopeOptions={recommendationScopeOptions}
          easeOfFixOptions={easeOfFixOptions}
          recommendationValidationMethodOptions={recommendationValidationMethodOptions}
          recommendation={recommendation}
        />
      ),
      disabled: false,
    },
    {
      title: 'Requirements',
      content: (
        <RequirementsFields
          control={control}
          errors={errors}
          setValue={setValue}
          licenseOptions={licenseOptions}
          prerequisiteLoading={prerequisiteLoading}
          prerequisiteOptions={prerequisiteOptions}
        />
      ),
      disabled: false,
    },
    {
      title: 'Remediation Steps',
      content: <RemediationStepsFields control={control} setValue={setValue} />,
      disabled: false,
    },
  ];

  return (
    <Modal
      style={{
        minWidth: '1000px',
      }}
      {...modalProps}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <HeaderContainer>
          <FormTitle>{`${isEdit ? 'Edit' : 'Create'} Recommendation`} </FormTitle>
          {!!recommendation && <span>{`Recommendation ID: ${recommendation.id}`}</span>}
          {!!recommendation && <span>{`Status: ${recommendation.status}`}</span>}
        </HeaderContainer>
        <FormContainer>
          <Tabs tabContent={tabContent} activeTab={activeTab} setActiveTab={setActiveTab} />
          {!!error && <ErrorMessage>{error}</ErrorMessage>}
        </FormContainer>
        {/* <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Product Name:</FieldLabel>
            <Controller
              control={control}
              name="productId"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={productOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {productOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Findings:</FieldLabel>
            <InputContainer>
              <FieldInput hasError={!!errors.findings} {...register('findings', { required: true })} />
              {!!errors.findings && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Reference:</FieldLabel>
            <InputContainer>
              <Controller control={control} name="reference" render={({ field }) => <FieldArea {...field} />} />
              {!!errors.reference && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>User Impact:</FieldLabel>
            <Controller
              control={control}
              name="userImpact"
              render={({ field }) => (
                <HtmlEditor initialValue={field.value} onChange={(content) => setValue('userImpact', content)} />
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Tenant Wide:</FieldLabel>
            <InputContainer>
              <Controller control={control} name="tenantWide" render={({ field }) => <CheckBox {...field} />} />
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel style={{ alignSelf: 'start' }}>Description:</FieldLabel>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <HtmlEditor initialValue={field.value} onChange={(content) => setValue('description', content)} />
              )}
            />
          </HorizontalAlignedContainer>

          <HorizontalAlignedContainer>
            <FieldLabel>Remediation:</FieldLabel>
            <Controller
              control={control}
              name="remediation"
              render={({ field }) => (
                <HtmlEditor initialValue={field.value} onChange={(content) => setValue('remediation', content)} />
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Prerequisite Reference:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="prerequisiteReference"
                render={({ field }) => <FieldArea {...field} />}
              />
              {!!errors.prerequisiteReference && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Prerequisite Description:</FieldLabel>
            <Controller
              control={control}
              name="prerequisiteDescription"
              render={({ field }) => (
                <HtmlEditor
                  initialValue={field.value}
                  onChange={(content) => setValue('prerequisiteDescription', content)}
                />
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Query:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="query"
                rules={{ required: !!recommendation && (recommendation.validationMethod as any) == 'Automatic' }}
                render={({ field }) => <FieldArea {...field} />}
              />
              {!!errors.query && <ErrorMessage>This field is required</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Operating System:</FieldLabel>
            <Controller
              control={control}
              name="operatingSystem"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={operatingSystemOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {operatingSystemOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>License:</FieldLabel>
            <Controller
              control={control}
              name="licenseId"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={licenseOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {licenseOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Severity:</FieldLabel>
            <Controller
              control={control}
              name="severity"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={severityOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {severityOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Category:</FieldLabel>
            <Controller
              control={control}
              name="category"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={categoryOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {categoryOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Ease of Fix:</FieldLabel>
            <Controller
              control={control}
              name="easeOfFix"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={easeOfFixOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {easeOfFixOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Estimated Work:</FieldLabel>
            <Controller
              control={control}
              name="estimatedWork"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={estimatedWorkOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {estimatedWorkOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Status:</FieldLabel>
            <Controller
              control={control}
              name="status"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={recommendationStatusOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {recommendationStatusOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Validation Method:</FieldLabel>
            <Controller
              control={control}
              name="validationMethod"
              render={({ field }) => (
                <Select
                  {...field}
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={recommendationValidationMethodOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {recommendationValidationMethodOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Scopes:</FieldLabel>
            <Controller
              control={control}
              name="scopes"
              render={({ field }) => (
                <Select
                  {...field}
                  mode="multiple"
                  allowClear
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={recommendationScopeOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {recommendationScopeOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Prerequisites:</FieldLabel>
            <Controller
              control={control}
              name="prerequisites"
              render={({ field }) => (
                <Select
                  {...field}
                  mode="multiple"
                  loading={prerequisiteLoading}
                  allowClear
                  value={field.value}
                  showSearch
                  style={{ flexBasis: '75%' }}
                  options={prerequisiteOptions}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                >
                  {prerequisiteOptions.map((opt) => (
                    <Select.Option key={opt.value} value={opt.value}>
                      {opt.label}
                    </Select.Option>
                  ))}
                </Select>
              )}
            />
          </HorizontalAlignedContainer>
        </FormContainer> */}
        <HorizontalAlignedContainer
          style={{
            gap: '10px',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <CreateButton>{isEdit ? 'Update' : 'Create'}</CreateButton>
              {!isEdit || (isEdit && recommendation && (recommendation.status as any) == 'Disabled') ? (
                <CreateButton
                  type="button"
                  onClick={async () => {
                    await trigger();
                    if (Object.keys(errors).length > 0) return;

                    const formValues = getValues();
                    onSaveAndPublish(formValues);
                  }}
                  style={{ padding: '12px 28px' }}
                >
                  Save and Publish
                </CreateButton>
              ) : (
                <CreateButton
                  type="button"
                  onClick={async () => {
                    await trigger();
                    if (Object.keys(errors).length > 0) return;

                    const formValues = getValues();
                    onDisableRecommendation(formValues);
                  }}
                >
                  Disable
                </CreateButton>
              )}
            </>
          )}
        </HorizontalAlignedContainer>
      </Container>
    </Modal>
  );
}
