import { useMemo, useState } from 'react';

type TCallBackProps = {
  currentPage: number;
  limit: number;
  skip: number;
  totalRows: number;
};

interface IUsePaginationProps {
  totalRows: number;
  limit: number;
  skip: number;
  limitIntervals: number[];
  onNext?: (callbackProps: TCallBackProps) => void;
  onPrev?: (callbackProps: TCallBackProps) => void;
  onPageClicked?: (callbackProps: TCallBackProps) => void;
  onLimitChanged?: (callbackProps: TCallBackProps) => void;
}

export function usePagination({
  limit,
  skip,
  totalRows,
  limitIntervals,
  onNext,
  onPrev,
  onPageClicked,
  onLimitChanged,
}: IUsePaginationProps) {
  const [limitState, setLimitState] = useState(limit);
  const [skipState, setSkipState] = useState(skip);
  const [totalRowsState, setTotalRowsState] = useState(totalRows);

  const totalPages = useMemo(() => {
    return Math.ceil(totalRowsState / limitState);
  }, [totalRowsState, limitState]);

  const activePageNumber = useMemo(() => {
    const newActivePage = Math.ceil(skipState === 0 ? 1 : skipState / limitState + 1);
    return newActivePage;
  }, [limitState, skipState]);

  const handleOnNext = () => {
    const newSkipState = skipState + limitState;
    setSkipState(newSkipState);

    onNext?.({
      totalRows: totalRowsState,
      currentPage: activePageNumber,
      limit: limitState,
      skip: newSkipState,
    });
  };
  const handleOnPrevious = () => {
    if (activePageNumber === 1) return;

    const newSkipState = skipState - limitState;
    setSkipState(newSkipState);

    onPrev?.({
      totalRows: totalRowsState,
      currentPage: activePageNumber,
      limit: limitState,
      skip: newSkipState,
    });
  };
  const handleLimitChange = (newLimit: number) => {
    /**
     * TODO: finish appropriate page number when changing the row limits
     * Temporary fix: every row limit change will go back to page 1
      let newSkip;
      let newActivePageNumber;

      const newTotalPages = Math.ceil(totalRowsState / newLimit);
      if (newTotalPages < totalPages && totalPages - activePageNumber < newTotalPages) {
        newActivePageNumber = newTotalPages - (totalPages - activePageNumber);
        newSkip = newActivePageNumber * newLimit - newLimit;
      } else {
        newSkip = activePageNumber * newLimit - newLimit;
      }
     */

    setSkipState(0);
    setLimitState(newLimit);
    onLimitChanged?.({
      totalRows: totalRowsState,
      currentPage: activePageNumber,
      limit: newLimit,
      skip: 0,
    });
  };
  const onPageNumberChange = (newPageNumber: number) => {
    const newSkip = newPageNumber * limitState - limitState;
    setSkipState(newSkip);
    onPageClicked?.({
      totalRows: totalRowsState,
      currentPage: newPageNumber,
      limit: limitState,
      skip: newSkip,
    });
  };

  const resetToDefaults = () => {
    setLimitState(limit);
    // force to page 1
    setSkipState(0);
  };

  const isNextDisabled = totalRowsState === 0 || activePageNumber === totalPages;
  const isPrevDisabled = totalRowsState === 0 || activePageNumber === 1;

  return {
    currentPage: activePageNumber,
    totalRows: totalRowsState,
    limit: limitState,
    skip: skipState,
    totalPages,
    limitIntervals,
    isNextDisabled,
    isPrevDisabled,
    setTotalRows: setTotalRowsState,
    onNext: handleOnNext,
    onPrev: handleOnPrevious,
    onLimitChanged: handleLimitChange,
    onPageNumberChange,
    resetToDefaults,
  };
}
