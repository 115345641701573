import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useState } from 'react';
import styled from 'styled-components';
import { useSetExemptRecommendation } from '@app/api/recommendation.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { Select, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import TextArea from 'antd/lib/input/TextArea';
import { useGetManyRecommendationExtended } from './hooks/useGetRecommendationExtended';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #272727;
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;
const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 60px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const HorizontalAlignedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NoBulletList = styled.ul`
  list-style-type: disc;
  padding: 0px 12px;
  margin: 0;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const reasonOptions = ['I use a third party tool', 'Risk Accepted', 'Other'];

interface ISetRecommendationExemptModalProps extends ModalFuncProps {
  recommendationIds: number[];
  recommendations?: TAccountRecommendationExtended[];
}

interface RecommendationListProps {
  recommendations: TAccountRecommendationExtended[];
}

function RecommendationList({ recommendations }: RecommendationListProps) {
  return (
    <FieldContainer>
      <FieldLabel>You selected to exempt the following recommendations:</FieldLabel>
      <NoBulletList>
        {recommendations.map((rec, index) => (
          <li key={`rec-${index}`}>{rec.findings}</li>
        ))}
      </NoBulletList>
    </FieldContainer>
  );
}

export default function ExemptRecommendationModal({
  recommendationIds,
  recommendations,
  ...modalProps
}: ISetRecommendationExemptModalProps) {
  const { recommendations: fetchedRecommendations, isLoading } = useGetManyRecommendationExtended({
    recommendationIds,
    recommendationsProp: recommendations,
  });

  const { mutateAsync: setExempt } = useSetExemptRecommendation();

  const [customReason, setCustomReason] = useState<string>('');
  const [reasonOption, setReasonOption] = useState<string>(reasonOptions[0]);
  const [error, setError] = useState<string | null>();

  const {
    register,
    handleSubmit,
    reset,
    control,
    // formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const handleExempt = async () => {
    const setExemptRequest = {
      accountRecommendationIds: fetchedRecommendations.map((rec) => rec.id),
      exempt: true,
      reason: reasonOption !== 'Other' ? reasonOption : `Other: ${customReason}`,
    };
    const response = await setExempt(setExemptRequest);
    if (response.status !== 201) {
      setError(`Exemption Failed: ${response?.data?.message}`);
    } else {
      reset();
      setError(null);
      modalProps.onOk?.();
    }
  };

  const handleCloseModal = () => {
    reset();
    setError(null);
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={'Exempt Recommendation'}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      footer={null}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <RecommendationList recommendations={fetchedRecommendations} />
          <FormContainer onSubmit={handleSubmit(handleExempt)}>
            <FieldContainer>
              <FieldLabel>Please specify reason for exempting these recommendations:</FieldLabel>
              <InputContainer>
                <Controller
                  control={control}
                  name="reasonOption"
                  render={({ field }) => (
                    <Select {...field} onChange={setReasonOption} value={reasonOption}>
                      {reasonOptions.map((value, index) => (
                        <Select.Option key={index} value={value}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
              </InputContainer>
            </FieldContainer>
            {reasonOption === 'Other' && (
              <FieldContainer>
                <FieldLabel>Enter exemption reason</FieldLabel>
                <InputContainer>
                  <TextArea
                    {...register('customReason', { required: false })}
                    onChange={(event) => setCustomReason(event.target.value)}
                  />
                </InputContainer>
              </FieldContainer>
            )}
            <HorizontalAlignedContainer
              style={{
                gap: '10px',
                justifyContent: 'center',
              }}
            >
              <CancelButton type="button" onClick={modalProps.onCancel}>
                Cancel
              </CancelButton>
              <ApplyButton
                type="submit"
                disabled={reasonOption === 'Other' && (customReason == null || customReason.length === 0)}
              >
                Exempt
              </ApplyButton>
            </HorizontalAlignedContainer>
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </FormContainer>
        </>
      )}
    </Modal>
  );
}
