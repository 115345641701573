import styled from 'styled-components';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BASE_COLORS, FONT_SIZE } from '@app/styles/themes/constants';
import { RiCloseFill } from 'react-icons/ri';

export const ScopingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const ScopeWrapper = styled.div`
  border-radius: 0.625rem;
  border: 1px solid #e9eaeb;
  width: 100%;
  background-color: #fff;
`;

export const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  font-weight: 600;
  margin-bottom: 1rem;
  cursor: pointer;
  padding: 1.25rem 1.375rem;
  margin-bottom: 0;
`;

export const ScopeContentWrapper = styled.div`
  padding: 0.938rem 1.25rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
// transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out, visibility 0.3s linear;
// overflow: hidden;
// max-height: ${({ isExpanded }: { isExpanded: boolean }) =>
//   isExpanded ? 'auto' : '0'}; // Adjust max-height as needed
// visibility: ${({ isExpanded }: { isExpanded: boolean }) => (isExpanded ? 'visible' : 'hidden')};
// padding-top: ${({ isExpanded }: { isExpanded: boolean }) => (isExpanded ? '1.25rem' : '0')};
// padding-bottom: ${({ isExpanded }: { isExpanded: boolean }) => (isExpanded ? '1.375rem' : '0')};

export const ScopeSelectedContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
`;

export const DeleteIcon = styled(RiDeleteBinLine)`
  width: 1rem;
  height: 1rem;
  color: #d81c2e;
`;

export const VerticalLine = styled.div`
  background-color: ${BASE_COLORS.ashgray};
  width: 3px;
`;

export const ScopeContent = styled.div`
  padding: 0.5rem 0.625rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.313rem;
  border: 1px solid #e9eaeb;
  max-width: max-content;
  font-size: ${FONT_SIZE.xxs};
  position: relative;
`;

export const RemoveIcon = styled(RiCloseFill)`
  width: 0.875rem;
  height: 0.875rem;
  position: relative;
  top: -1px;
  cursor: pointer;
`;
