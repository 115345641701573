import { Editor } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';
import { Level } from '@tiptap/extension-heading';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa6';

const StylingMenuDropdown = styled.div`
  position: relative;
`;

const IconWrapper = styled.button<{ showDropdown: boolean }>`
  background-color: ${(props) => (props.showDropdown ? 'lightgray' : 'transparent')};
  border: none;
  border-radius: 6px;
  padding: 0 4px;
  width: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;

  &:hover {
    background-color: lightgray;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
`;

const OptionContainer = styled.div<{ showDropdown?: boolean }>`
  display: ${(props) => (props.showDropdown ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  width: auto;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const Option = styled.div<{ isActive?: boolean }>`
  padding: 4px 10px;
  cursor: pointer;
  font-size: 16px;
  color: black;
  background-color: ${(props) => (props.isActive ? 'lightgray' : 'transparent')};

  &:hover {
    background-color: lightgray;
  }
`;

const StylingDropdown = ({ editor }: { editor: Editor }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('Paragraph');
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleOptionSelect = (value: string) => {
    setSelectedValue(value);
    editor.chain().focus();
    if (value === 'Paragraph') {
      editor.chain().setParagraph().run();
    } else if (value.startsWith('H')) {
      const level = parseInt(value.slice(1), 10) as Level;
      editor.chain().focus().toggleHeading({ level }).run();
    }
    setShowDropdown(false);
  };

  return (
    <StylingMenuDropdown ref={dropdownRef}>
      <IconWrapper
        title="Styles"
        type="button"
        onClick={() => setShowDropdown(!showDropdown)}
        showDropdown={showDropdown}
      >
        {selectedValue}
        <FaChevronDown size={10} />
      </IconWrapper>
      <OptionContainer showDropdown={showDropdown}>
        {['Paragraph', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'].map((heading) => (
          <Option key={heading} isActive={selectedValue === heading} onClick={() => handleOptionSelect(heading)}>
            {heading.replace('heading', 'H')}
          </Option>
        ))}
      </OptionContainer>
    </StylingMenuDropdown>
  );
};

export default StylingDropdown;
