import useToggle from '@app/hooks/useToggle';
import { TSubscriptionForm } from './ManageSubscriptionForm';
import { TSubscriptionType } from '@app/types/subscriptionType';

interface IUseSubscriptionFormProps {
  onCancel?: () => void;
  onOk?: (data: TSubscriptionForm) => void;
  subscription?: TSubscriptionType;
}

export default function useSubscriptionForm(props?: IUseSubscriptionFormProps) {
  const [isModalOpen, toggle, setIsModalOpen] = useToggle();

  const handleOnOk = (data: TSubscriptionForm) => {
    props?.onOk?.(data);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setIsModalOpen(false);
  };

  return {
    open: isModalOpen,
    subscription: props?.subscription,
    toggle,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
  };
}
