import { TGroupExtended } from '@app/types/groupExtended';
import { TGroupForm } from './GroupForm';
import useToggle from '@app/hooks/useToggle';

interface IUseGroupProps {
  onOk?: (data: TGroupForm) => void;
  onCancel?: () => void;
  group?: TGroupExtended;
}

const useGroups = (props?: IUseGroupProps) => {
  const [isModalOpen, toggle, setIsModalOpen] = useToggle();

  const handleOnOk = (data: TGroupExtended) => {
    props?.onOk?.(data);
  };

  const handleOnCancel = () => {
    props?.onCancel?.();
    setIsModalOpen(false);
  };

  return {
    group: props?.group,
    onOk: handleOnOk,
    onCancel: handleOnCancel,
    toggle,
    open: isModalOpen,
  };
};

export default useGroups;
