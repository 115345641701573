import { useEffect, useState } from 'react';
import { TUsersExtended } from '@app/types/users';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import styled from 'styled-components';
import { VerticalContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Space, Spin, Radio, Popover, Dropdown, MenuProps, RadioChangeEvent, Typography } from 'antd';
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { TGroupExtended } from '@app/types/groupExtended';
import { getEnumValue } from '@app/services/enum.service';
import { useUpdateUserRoleAndGroup } from '@app/api/user.api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const FieldLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #272727;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 60px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;

const DropdownContainer = styled.div`
  width: 100%;
  padding: 8px;
  border: 1px solid #e9eaeb;
  border-radius: 10px;
`;

const StyledSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onOk?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onOk, onApplyDisabled }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <CancelButton onClick={() => onCancel?.()}>Cancel</CancelButton>
      <ApplyButton onClick={() => onOk?.()} disabled={onApplyDisabled}>
        Update
      </ApplyButton>
    </FooterContainer>
  );
};

interface IEditUserModalProps extends ModalFuncProps {
  user: TUsersExtended | null;
  groups?: TGroupExtended[];
}

const DEFAULT_ROLE = getEnumValue('UserRole', 'User') || 2;

const EditUserModal = ({ user, groups, ...modalProps }: IEditUserModalProps) => {
  const [role, setRole] = useState(DEFAULT_ROLE);
  const [selectedGroup, setSelectedGroup] = useState<TGroupExtended | null>(null);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const { mutate: updateUserRoleAndGroup, isLoading } = useUpdateUserRoleAndGroup();
  const { Text } = Typography;

  const items: MenuProps['items'] = groups?.map((g) => ({
    label: g.name,
    key: g.id,
  }));

  useEffect(() => {
    if (user) {
      const enumValueUserRole = getEnumValue('UserRole', user.role.toString()) || DEFAULT_ROLE;
      setRole(enumValueUserRole);
      setIsAdminRole(enumValueUserRole === getEnumValue('UserRole', 'Admin'));
      setSelectedGroup(groups?.find((g) => Number(g.id) === user.groupId) || null);
    }
  }, [user, groups]);

  const onClick = (e: any) => {
    const group = groups?.find((g) => g.id.toString() === e.key);
    setSelectedGroup(group || null);
  };

  const handleUpdateUserRoleAndGroup = () => {
    if (user) {
      updateUserRoleAndGroup(
        {
          id: user.id,
          payload: {
            role: role,
            groupId: role === getEnumValue('UserRole', 'Admin') ? null : Number(selectedGroup?.id),
          },
        },
        {
          onSuccess: (data) => {
            if (data) {
              modalProps.onOk?.();
            }
          },
        },
      );
    }
  };

  const handleCloseModal = () => {
    setRole(DEFAULT_ROLE);
    setIsAdminRole(false);
    setSelectedGroup(null);
    modalProps.onCancel?.();
  };

  const handleRoleChange = (e: RadioChangeEvent) => {
    setRole(e.target.value as number);
    setIsAdminRole(e.target.value === getEnumValue('UserRole', 'Admin'));
  };

  return (
    <Modal
      {...modalProps}
      style={{ minWidth: '600px' }}
      title="Edit User"
      destroyOnClose={false}
      closable={false}
      footer={
        isLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : (
          <ModalFooter onCancel={handleCloseModal} onOk={handleUpdateUserRoleAndGroup} />
        )
      }
    >
      <Container>
        <Text type="secondary">Define the actions users in this group can perform</Text>
        <FieldContainer>
          <FieldLabel>User Email Address:</FieldLabel>
          <Text>{user?.email}</Text>
        </FieldContainer>

        <FieldContainer>
          <FieldLabel>Assigned Role:</FieldLabel>
          <Radio.Group name="radiogroup" value={role} onChange={handleRoleChange}>
            <Radio value={1}>
              <VerticalContainer>
                Administrator
                <Text type="secondary" style={{ fontSize: '14px' }}>
                  Users with this role have the highest permissions level and can perform all actions, including
                  account-level settings configurations
                </Text>
              </VerticalContainer>
            </Radio>

            <Radio value={2}>
              <VerticalContainer>
                User
                <Text type="secondary" style={{ fontSize: '14px' }}>
                  Permissions for this role are defined according to the permissions assigned to the user’s group
                </Text>
              </VerticalContainer>
            </Radio>
          </Radio.Group>
        </FieldContainer>

        {isAdminRole ? null : (
          <FieldContainer>
            <FieldLabel>Assigned Group:</FieldLabel>
            <DropdownContainer>
              <Dropdown menu={{ items, onClick }} placement="bottomLeft" trigger={['click']}>
                <StyledSpace>
                  {selectedGroup ? selectedGroup.name : 'No Group'}
                  <CaretDownOutlined />
                </StyledSpace>
              </Dropdown>
            </DropdownContainer>
          </FieldContainer>
        )}
      </Container>
    </Modal>
  );
};

export default EditUserModal;
