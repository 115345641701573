import { useAppSelector } from './reduxHooks';
import { dashboardRoutes } from '../components/router/routes';
import ErrorNotAllowedPage from '../pages/ErrorNotAllowedPage';
import { createElement } from 'react';

export const useGetRoutes = () => {
  const allowedScreens = useAppSelector((state) => state.app.allowedScreensAndActions?.screens);
  if (!allowedScreens) return;

  const filteredChildrenDashboarRoutes = dashboardRoutes.map((dr) => ({
    ...dr,
    // children: dr.children?.filter((drc) => allowedScreens.includes(drc.key)),
    children: dr.children?.map((drc) => ({
      ...drc,
      element: allowedScreens.includes(drc.key) ? drc.element : createElement(ErrorNotAllowedPage),
    })),
  }));

  const filteredDashboardRoutes = filteredChildrenDashboarRoutes.map((dr) => ({
    ...dr,
    element: allowedScreens.includes(dr.key) ? dr.element : createElement(ErrorNotAllowedPage),
  }));

  return filteredDashboardRoutes;
};
