import styled from 'styled-components';

export const StyledStatus = styled.div`
  border-radius: 5px;
  padding: 0.75rem 0.5rem;
  font-size: 0.75rem;
  height: 1.875rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  fontweight: 500;
  width: 100%;
`;

export const ComplianceStatusBadge = styled(StyledStatus)<{ isCompliant: boolean | null }>`
  ${(props) =>
    props.isCompliant == true
      ? `background-color: #30af5b1a;
    color: #30af5b;
    border: 1px solid #30af5b80;`
      : props.isCompliant == false
      ? `background-color: #d81c2e1a;
    color: #d81c2e;
    border: 1px solid #d81c2e80;`
      : `background-color: #d3d3d3; /* light gray background */
  color: #a9a9a9; /* dark gray text */
  border-color: #a9a9a9; /* dark gray border */`};
`;

export const ExemptionStatusBadge = styled(StyledStatus)<{ isExempted: boolean }>`
  ${(props) =>
    props.isExempted &&
    `
    color: #ff7b00;
    background-color: #ffcd90;
    `}
`;

export const ChangeTypeBadge = styled(StyledStatus)<{ type: 'improvement' | 'regression' | 'assignment' }>`
  ${(props) => {
    return props.type === 'improvement'
      ? `background-color: #30af5b1a;
    color: #30af5b;
    border: 1px solid #30af5b80;`
      : props.type === 'regression'
      ? `background-color: #d81c2e1a;
    color: #d81c2e;
    border: 1px solid #d81c2e80;`
      : `background-color: #d3d3d3; /* light gray background */
  color: #a9a9a9; /* dark gray text */
  border-color: #a9a9a9; /* dark gray border */`;
  }};
`;
