import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import CreateGroupButton from './CreateGroupButton';

interface IManageGroupsHeaderProps {
  onCreateGroup: () => void;
}

const ManageGroupsHeader = ({ onCreateGroup }: IManageGroupsHeaderProps) => {
  return (
    <ComponentHeader
      title="Groups Management"
      subTitle="Create and manage user groups. Use groups allow fine-grained control of permissions and access provided to users in your environment."
      actionButtons={
        <>
          <CreateGroupButton title="Create a new group" onPress={onCreateGroup} />
        </>
      }
    />
  );
};

export default ManageGroupsHeader;
