import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import { Divider, Dropdown, MenuProps } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';

import { FilterIcon, TrashIcon } from '@app/components/icons';
import { CustomDropdown } from './CustomDropdown';
import { TDataCol, TFilterValue } from '../types';
import { CustomFilterDropdown } from './CustomFilterDropdown';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e9eaeb;
  background-color: #fff;
  padding: 6px 12px;
  margin-bottom: 20px;
  border-radius: 8px;
  gap: 4px;
`;

const IconContainer = styled.div`
  background-color: #f8f8f8;
  padding: 6px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 94px;
`;

const FilterChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e9eaeb;
  border-radius: 8px;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  margin-right: 6px;
  margin-bottom: 10px;
`;

const FilterChipColumnText = styled.span`
  font-weight: 400;
  font-size: 12px;
`;

const FilterChipCloseButton = styled(CloseOutlined)`
  margin-left: 5px;
  cursor: pointer;
`;

const ClearFilterIcon = styled(FilterChip)<{ isActive: boolean }>`
  align-self: flex-start;
  margin-top: 4px;
  font-weight: 600;
  color: ${(props) => (props.isActive ? '#272727' : '#acacac')};
  flex-shrink: 0;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
  gap: 4px;
  ${(props) => (props.isActive ? 'border: 1px solid #272727;' : '')}
`;

const Text = styled.span`
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 4px;
`;

export interface IFiltersProps<T> {
  columnFilters: ItemType[];
  appliedFilters: TFilterValue[];
  searchColumnValue: string;
  selectedFilterColumn: TDataCol<T> | null;
  isDropdownOpen: boolean;
  onSearchColumn: (e: ChangeEvent<HTMLInputElement>) => void;
  onFilterOptionClicked: MenuProps['onClick'];
  toggleDropdown: (open: boolean) => void;
  onBackClicked: () => void;
  onApply: (filterObj: TFilterValue) => void;
  onFilterRemove: (index: number) => void;
  onClearFilters: () => void;
}

function Filters<T>({
  columnFilters,
  appliedFilters,
  searchColumnValue,
  selectedFilterColumn,
  isDropdownOpen,
  onSearchColumn,
  onFilterOptionClicked,
  toggleDropdown,
  onBackClicked,
  onApply,
  onClearFilters,
  onFilterRemove,
}: IFiltersProps<T>) {
  const { t } = useTranslation();
  const appValues = useSelector<RootState>((state) => state.app.appValues);

  const getEnumValues = (enumKey: string, value: string): string => {
    const enumValues = JSON.parse(value) as number[];

    return enumValues
      .map((val) => {
        const appVal = (appValues as any)?.[enumKey]?.find((appVal: any) => appVal.value == val);
        return appVal ? t(`appValues.${enumKey}.${appVal.label}`) : `Unknown (${val})`;
      })
      .join(', ');
  };

  const formatFilterValue = (filter: any): string => {
    const { type, value, enumKey } = filter;
    if (type === 'enum' || type === 'enumArray') {
      try {
        return getEnumValues(enumKey || '', value);
      } catch (error) {
        throw new Error('Error parsing enum values:', error as Error);
      }
    }

    if (type === 'textOptions') {
      try {
        const parsedValue = JSON.parse(value);
        if (Array.isArray(parsedValue)) {
          return parsedValue.join(', ');
        }
      } catch (error) {
        throw new Error('Error parsing textOptions value:', error as Error);
      }
    }

    if (type === 'date') {
      try {
        const dateValues = JSON.parse(value);
        return `from: ${format(dateValues[0], 'dd/MM/yyy')} - to: ${format(dateValues[1], 'dd/MM/yyy')}`;
      } catch (error) {
        throw new Error('Error parsing date value:', error as Error);
      }
    }

    return value;
  };

  return (
    <Container>
      <FilterContainer style={{ flexGrow: 1, paddingBottom: '0px' }}>
        {/* <IconContainer style={{ alignSelf: 'flex-start', marginTop: '8px' }}>
          <FilterIcon />
        </IconContainer> */}

        <Divider type="vertical" style={{ alignSelf: 'flex-start', marginTop: '16px' }} />

        <FilterChipsContainer>
          <FilterChipsContainer>
            {appliedFilters.length === 0 ? (
              <Text role="button" style={{ flexShrink: 0, marginRight: '24px' }}>
                No Filters Selected
              </Text>
            ) : null}
            {appliedFilters.map((filter, index) => (
              <Dropdown
                key={index}
                menu={{
                  items: columnFilters,
                  onClick: onFilterOptionClicked,
                }}
                trigger={['click']}
                arrow
                dropdownRender={(menu) => (
                  <CustomFilterDropdown
                    title={filter.title?.toString() || ''}
                    dataIndex={filter.column.toString() || ''}
                    type={(filter as any).type}
                    options={(filter as any).options}
                    multipleSelect={(filter as any).multipleSelect}
                    hasNullOption={(filter as any).hasNullOption}
                    onBackClicked={onBackClicked}
                    onApply={onApply}
                    enumKey={filter.enumKey ?? undefined}
                    defaultValues={{
                      type: filter.type as any,
                      value: filter.value,
                    }}
                  />
                )}
              >
                <FilterChip key={index}>
                  <FilterChipColumnText>{filter.title || filter.column}</FilterChipColumnText>
                  {`: ${formatFilterValue(filter)}`}
                  <FilterChipCloseButton role="button" onClick={() => onFilterRemove?.(index)} />
                </FilterChip>
              </Dropdown>
            ))}
          </FilterChipsContainer>
          <Dropdown
            menu={{
              items: columnFilters,
              onClick: onFilterOptionClicked,
            }}
            trigger={['click']}
            arrow
            open={isDropdownOpen}
            onOpenChange={toggleDropdown}
            dropdownRender={(menu) =>
              selectedFilterColumn ? (
                <CustomFilterDropdown
                  title={
                    typeof selectedFilterColumn.title === 'string'
                      ? selectedFilterColumn.title?.toString()
                      : !!selectedFilterColumn.altLabel
                      ? selectedFilterColumn.altLabel
                      : ''
                  }
                  dataIndex={selectedFilterColumn.dataIndex?.toString() || ''}
                  type={(selectedFilterColumn as any).type}
                  options={(selectedFilterColumn as any).options}
                  multipleSelect={(selectedFilterColumn as any).multipleSelect}
                  hasNullOption={(selectedFilterColumn as any).hasNullOption}
                  onBackClicked={onBackClicked}
                  onApply={onApply}
                  enumKey={(selectedFilterColumn as any).enumValuesKey ?? undefined}
                />
              ) : (
                <CustomDropdown menu={menu} inputValue={searchColumnValue} onChange={onSearchColumn} />
              )
            }
          >
            <Text role="button" style={{ flexShrink: 0 }}>
              Add Filter
            </Text>
          </Dropdown>
        </FilterChipsContainer>

        <div style={{ flexGrow: 1 }} />

        <ClearFilterIcon role="button" isActive={appliedFilters.length > 0} onClick={() => onClearFilters?.()}>
          <TrashIcon fill={appliedFilters.length > 0 ? '#D81C2E' : '#ACACAC'} />
          Clear Filters
        </ClearFilterIcon>
      </FilterContainer>
    </Container>
  );
}

export default Filters;
