import { format, parseISO } from 'date-fns';

import {
  HorizontalAlignedContainer,
  SpaceGrower,
  VerticalContainer,
} from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import { Popover } from 'antd';
import { getDetailedChanges } from '@app/utils/utils';
import { getEnumLabel, getEnumValue } from '@app/services/enum.service';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';

interface IComplianceStatusChangesProps {
  event: TRecommendationEventType;
  recommendationValidationMethod: number;
  title?: string;
  showTimeOnly?: boolean;
}

const ComplianceStatusChanges = ({
  event,
  recommendationValidationMethod,
  title,
  showTimeOnly,
}: IComplianceStatusChangesProps) => {
  const { date, change_type, user, comment, change_details, snapshot_type } = event;

  const complianceText = change_type === 'improvement' ? 'Compliant' : 'Non-Compliant';
  const complianceTextColor = change_type === 'improvement' ? 'green' : 'red';
  const isManual =
    (recommendationValidationMethod as unknown as string) === getEnumLabel('RecommendationValidationMethod', 2);
  const complianceMessage =
    snapshot_type == getEnumValue('RecommendationSnapshotType', 'InitialScan')
      ? `Recommendation was created with ${complianceText} status.`
      : isManual
      ? `Recommendation was marked as ${complianceText} by ${user}${comment?.length === 0 ? '.' : `, who noted:`}`
      : `Recommendation automatically became ${complianceText}. `;

  return (
    <VerticalContainer style={{ gap: '4px', marginBottom: '12px' }}>
      <HorizontalAlignedContainer>
        <span style={{ fontSize: '16px', fontWeight: '600', color: complianceTextColor }}>{`${
          title ? title : 'Recommendation'
        } ${
          snapshot_type == getEnumValue('RecommendationSnapshotType', 'InitialScan') ? 'was created as' : 'became '
        } ${complianceText}`}</span>
        <SpaceGrower />
        <span
          style={{
            fontSize: '14px',
            fontWeight: 'normal',
            color: 'gray',
            marginRight: '12px',
          }}
        >{`${!showTimeOnly ? `${format(parseISO(date), 'dd MMM, yyyy')} at` : ''} ${format(
          parseISO(date),
          "HH:mm aaaaa'm'",
        )}`}</span>
      </HorizontalAlignedContainer>
      <span style={{ fontSize: '14px' }}>
        {complianceMessage}
        {isManual && <HtmlEditor initialValue={comment} readOnly />}
        {!isManual && change_details && change_details.length != 0 && (
          <span>
            <Popover
              style={{ marginLeft: '16px', fontSize: '14px' }}
              content={
                <VerticalContainer>
                  {change_details.map((changes, i) => (
                    <div key={i}>{getDetailedChanges(changes)}</div>
                  ))}
                </VerticalContainer>
              }
            >
              <a>View possible causes</a>
            </Popover>
          </span>
        )}
      </span>
    </VerticalContainer>
  );
};

export { ComplianceStatusChanges };
