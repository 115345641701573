import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { TGroupExtended } from '@app/types/groupExtended';
import { Modal, ModalFuncProps } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import { TGroupPermission } from '@app/types/groupPermission';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { TPermission } from '@app/types/groupPermission';
import { CheckBox } from '@app/components/header/components/searchDropdown/searchOverlay/SearchFilter/SearchFilter.styles';
import RecommendationScoping from '../recommendation-scoping/components/RecommendationScoping';

const StyledModal = styled(Modal)`
  min-width: 900px;
`;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 35%;
`;

const CategoryLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 35%;
  align-self: flex-start;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CreateButton = styled.button`
  border: ${(props) => (props.disabled ? '1px solid #acacac' : '1px solid #3a50d1')};
  border-radius: 10px;
  background-color: ${(props) => (props.disabled ? '#acacac' : '#3a50d1')};
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHorizontalContainer = styled(HorizontalAlignedContainer)`
  gap: 10px;
  justify-content: center;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export type TGroupForm = {
  id?: string;
  name: string;
  description: string;
  creationTime: Date;
  lastUpdatedTime: Date;
  status: number;
  accountId: string;
  numberOfUsers?: string;
  permissions?: number[];
  recommendationScopingEnabled: boolean;
  recommendationsFilter?: string;
};

interface IGroupProps extends ModalFuncProps {
  isEdit?: boolean;
  group?: TGroupExtended;
  error?: string | null;
  groupPermissions: TGroupPermission[];
  setRecommendationsFilter?: (filter: string) => void;
}

const GroupForm = ({
  isEdit,
  group,
  error,
  groupPermissions: categories,
  setRecommendationsFilter,
  ...modalProps
}: IGroupProps) => {
  const [localRecommendationsFilter, setLocalRecommendationsFilter] = useState<string | undefined>(undefined);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<TGroupForm>({ mode: 'onBlur' });

  const recommendationScopingEnabled = watch('recommendationScopingEnabled', false);
  const hasName = watch('name', '');
  const hasDescription = watch('description', '');

  const onFilterChange = (filter: string) => {
    const filterString = filter.split('&')?.find((f) => f.includes('$filter')) || '';
    setRecommendationsFilter?.(filterString);
    setLocalRecommendationsFilter(filterString);
  };

  useEffect(() => {
    if (modalProps.open && !!group) {
      reset({
        id: group.id,
        name: group.name,
        description: group.description,
        accountId: group.accountId,
        numberOfUsers: group.numberOfUsers,
        permissions: group.permissions || [],
        recommendationScopingEnabled: group.recommendationScopingEnabled,
      });

      setLocalRecommendationsFilter(group.recommendationsFilter || undefined);
    } else {
      reset({
        id: undefined,
        name: '',
        description: '',
        accountId: '',
        numberOfUsers: '0',
        permissions: [],
        recommendationScopingEnabled: undefined,
      });

      setLocalRecommendationsFilter(undefined);
    }
  }, [modalProps.open, group, reset]);

  const onSubmit: SubmitHandler<TGroupForm> = (data) => {
    modalProps.onOk?.({ ...data, recommendationsFilter: localRecommendationsFilter });
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  const handleCheckboxChange = (permissions: TPermission[], checkedValues: CheckboxValueType[]) => {
    const currentPermissions = getValues('permissions') || [];
    const newPermissions = [
      ...Array.from(
        new Set([...currentPermissions.filter((v) => !permissions.some((p) => p.id === v)), ...checkedValues]),
      ),
    ];
    setValue('permissions', newPermissions as number[]);
  };

  const handleSingleCheckboxChange = (permissions: TPermission[], checkedValues: CheckboxValueType[]) => {
    const currentPermissions = getValues('permissions') || [];
    const selectedPermission = checkedValues.length > 0 ? [checkedValues[checkedValues.length - 1]] : [];

    // Filter out permissions that are not in the current group
    const filteredPermissions = currentPermissions.filter((perm) => !permissions.some((p) => p.id === perm));

    // Combine the filtered permissions with the newly selected permission
    const newPermissions = [...filteredPermissions, ...(selectedPermission as number[])];

    setValue('permissions', newPermissions);
  };

  return (
    <StyledModal {...modalProps} destroyOnClose closable={false} maskClosable={false} footer={null}>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{isEdit ? 'Edit group' : 'Create a new group'}</FormTitle>
        <span>Define the actions users in this group can perform</span>
        <FormContainer>
          <HorizontalAlignedContainer>
            <FieldLabel>Group Name:</FieldLabel>
            <InputContainer>
              <FieldInput defaultValue={group?.name} {...register('name', { required: 'Name is required' })} />
              {!!errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>

          <HorizontalAlignedContainer>
            <FieldLabel>Description:</FieldLabel>
            <InputContainer>
              <FieldInput
                defaultValue={group?.description}
                {...register('description', { required: 'Description is required' })}
              />
              {!!errors.description && <ErrorMessage>{errors.description.message}</ErrorMessage>}
            </InputContainer>
          </HorizontalAlignedContainer>

          <HorizontalAlignedContainer style={{ marginBottom: '20px' }}>
            <CategoryLabel>Allowed actions:</CategoryLabel>
            <InputContainer>
              <Controller
                name="permissions"
                control={control}
                render={({ field }) => (
                  <>
                    {categories &&
                      categories.map((category, index) => (
                        <CategoryContainer key={index}>
                          <FieldLabel>{category.displayName}</FieldLabel>
                          {category.allowMultiSelect ? (
                            <Checkbox.Group
                              {...field}
                              value={field.value}
                              onChange={(value) => handleCheckboxChange(category.groupPermissions, value)}
                            >
                              {category.groupPermissions.map((permission, pIndex) => (
                                <CheckboxContainer key={pIndex}>
                                  <Checkbox value={permission.id}>{permission.description}</Checkbox>
                                </CheckboxContainer>
                              ))}
                            </Checkbox.Group>
                          ) : (
                            <Checkbox.Group
                              {...field}
                              value={field.value}
                              onChange={(value) => handleSingleCheckboxChange(category.groupPermissions, value)}
                            >
                              {category.groupPermissions.map((permission, pIndex) => (
                                <CheckboxContainer key={pIndex}>
                                  <Checkbox value={permission.id}>{permission.description}</Checkbox>
                                </CheckboxContainer>
                              ))}
                            </Checkbox.Group>
                          )}
                        </CategoryContainer>
                      ))}
                  </>
                )}
              />
            </InputContainer>
          </HorizontalAlignedContainer>

          <HorizontalAlignedContainer>
            <FieldLabel>Restrict Recommendation Access:</FieldLabel>
            <InputContainer>
              <Controller
                control={control}
                name="recommendationScopingEnabled"
                render={({ field }) => (
                  <CheckBox {...field} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
                )}
              />
            </InputContainer>
          </HorizontalAlignedContainer>

          {recommendationScopingEnabled && (
            <RecommendationScoping onFilterChange={onFilterChange} initialFilter={localRecommendationsFilter} />
          )}
        </FormContainer>

        <StyledHorizontalContainer>
          <>
            <CancelButton type="button" onClick={handleCancel}>
              Cancel
            </CancelButton>
            <CreateButton type="submit" disabled={!hasName || !hasDescription}>
              {isEdit ? 'Update' : 'Create'}
            </CreateButton>
          </>
        </StyledHorizontalContainer>
      </Container>
    </StyledModal>
  );
};

export default GroupForm;
