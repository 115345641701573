import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import styled from 'styled-components';
import { Popover } from 'antd';
import { getImpersonationToken } from '@app/services/localStorage.service';

const Text = styled.h1`
  font-size: 1em;
  text-align: center;
  margin: 0;
`;

const TooltipContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #13264d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

const ProfileWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const user = useAppSelector((state) => state.user.user);

  const impersonationToken = getImpersonationToken();

  return user ? (
    <BasePopover content={impersonationToken ? null : <ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[15, 0]} align="stretch">
        <ProfileWrapper>
          <Popover
            showArrow={false}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            content={
              <TooltipContainer>
                {isTablet && (
                  <S.EmailCol>
                    <Text>{user.email}</Text>
                  </S.EmailCol>
                )}
              </TooltipContainer>
            }
          >
            <UserOutlined
              style={{
                fontSize: 24,
                marginRight: '4px',
                color: '#13264D',
              }}
            />
          </Popover>
        </ProfileWrapper>
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
