import React from 'react';

import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import { withLoading } from '@app/hocs/withLoading.hoc';
import HomePage from '@app/pages/HomePage';
import AlertDynamicPage from '@app/pages/AlertDynamicPage';
import ManageUsersPage from '@app/pages/ManageUsersPage';
import ManageAccountsPage from '@app/pages/ManageAccountsPage';
import ManageRecommendationsPage from '@app/pages/ManageRecommendationsPage';
import InsightsManagementPage from '@app/pages/InsightsManagementPage';
import JoinPage from '@app/pages/JoinPage';
import ImpersonateUserPage from '@app/pages/ImpersonateUserPage';
import RecommendationHistoryPage from '@app/pages/RecommendationHistory';
import ManageReportsPage from '@app/pages/ManageReportsPage';
import InsightsPage from '@app/pages/Insights';
import ReportsPage from '@app/pages/ReportsPage';
import ManageVendorsPage from '@app/pages/ManageVendorsPage';
import RegulationsCompliancePage from '@app/pages/RegulationsCompliancePage';
import ManageRegulationsPage from '@app/pages/ManageRegulationsPage';
import GeneralSettingsPage from '@app/pages/GeneralSettings';
import ManageGroupsPage from '@app/pages/ManageGroupsPage';
import MessagingPage from '@app/pages/MessagingPage';
import SubscriptionTypesPage from '@app/pages/SubscriptionTypesPage';

const DataConnectorsPage = React.lazy(() => import('@app/pages/DataConnectorsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/AlertsPage'));
const RecommendationsPage = React.lazy(() => import('@app/pages/RecommendationsPage'));
const RegulationInformationPage = React.lazy(() => import('@app/pages/RegulationInformationPage'));
const AlertsHistoryPage = React.lazy(() => import('@app/pages/AlertsHistoryPage'));
const ActiveAlertsPage = React.lazy(() => import('@app/pages/ActiveAlertsPage'));
const ManageSettingsPage = React.lazy(() => import('@app/pages/ManageSettingsPage'));
const StoryPage = React.lazy(() => import('@app/pages/StoryPage'));
const StoryRecommendationsPage = React.lazy(() => import('@app/pages/StoryRecommendationsPage'));
const ManageStoriesPage = React.lazy(() => import('@app/pages/ManageStoriesPage'));

// recommendation pages
const Recommendations = withLoading(RecommendationsPage);
const RecommendationHistory = withLoading(RecommendationHistoryPage);
const RegulationInformation = withLoading(RegulationInformationPage);
const RegulationsCompliance = withLoading(RegulationsCompliancePage);
const Story = withLoading(StoryPage);
const StoryRecommendations = withLoading(StoryRecommendationsPage);

// insights pages
const Insights = withLoading(InsightsPage);

// alerts pages
const Alerts = withLoading(AlertsPage);
const AlertsHistory = withLoading(AlertsHistoryPage);
const ActiveAlerts = withLoading(ActiveAlertsPage);

// reports pages
const Reports = withLoading(ReportsPage);

// global pages
const Home = withLoading(HomePage);

// admin pages
const GeneralSettings = withLoading(GeneralSettingsPage);

// superadmin pages
const ManageAccounts = withLoading(ManageAccountsPage);
const ManageRecommendations = withLoading(ManageRecommendationsPage);
const InsightsManagement = withLoading(InsightsManagementPage);
const ManageReports = withLoading(ManageReportsPage);
const ManageVendors = withLoading(ManageVendorsPage);
const ManageRegulations = withLoading(ManageRegulationsPage);
const ManageStories = withLoading(ManageStoriesPage);
const DataConnectors = withLoading(DataConnectorsPage);
const ManageSettings = withLoading(ManageSettingsPage);
const SubscriptionTypes = withLoading(SubscriptionTypesPage);

export const authRoutes = [
  {
    key: 'Login',
    path: 'login',
    element: <LoginPage />,
  },
  {
    key: 'Signup',
    path: 'sign-up',
    element: <SignUpPage />,
  },
  {
    key: 'Join',
    path: 'join',
    element: <JoinPage />,
  },
  {
    key: 'Login',
    path: 'login',
    element: <LoginPage />,
  },
];

export const dashboardRoutes = [
  {
    key: 'HomePage',
    path: 'dashboard',
    element: <Home />,
  },
  {
    key: 'Recommendations',
    path: 'recommendations',
    element: <Recommendations />,
    children: [
      {
        key: 'Recommendations',
        path: ':recommendationId',
        element: <Recommendations />,
      },
    ],
  },
  {
    key: 'RecommendationHistory',
    path: 'recommendation-history',
    element: <RecommendationHistory />,
  },

  {
    key: 'RegulationsCompliance',
    path: 'regulations-compliance',
    element: <RegulationsCompliance />,
  },
  {
    key: 'RegulationInformation',
    path: 'regulation-information/:regulationId',
    element: <RegulationInformation />,
  },
  {
    key: 'Stories',
    path: 'stories',
    element: <Story />,
  },
  {
    key: 'Stories',
    path: 'story/:storyId',
    element: <StoryRecommendations />,
  },
  {
    key: 'Alerts',
    path: 'manage-alerts',
    element: <Alerts />,
  },
  {
    key: 'AlertsHistory',
    path: 'alerts-history',
    element: <AlertsHistory />,
    children: [
      {
        key: 'AlertsHistory',
        path: ':historyId',
        element: <AlertsHistory />,
      },
    ],
  },
  {
    key: 'ActiveAlerts',
    path: 'active-alerts',
    element: <ActiveAlerts />,
    children: [
      {
        key: 'ActiveAlerts',
        path: ':alertId',
        element: <ActiveAlerts />,
      },
    ],
  },
  {
    key: 'Alerts',
    path: 'alert/create',
    element: <AlertDynamicPage />,
  },
  {
    key: 'Alerts',
    path: 'alert/update/:id',
    element: <AlertDynamicPage />,
  },
  {
    key: 'Reports',
    path: 'reports',
    element: <Reports />,
  },
  {
    key: 'Insights',
    path: 'insights',
    element: <Insights />,
    children: [
      {
        key: 'Insights',
        path: ':insightId',
        element: <Insights />,
      },
    ],
  },
  {
    key: 'GeneralSettings',
    path: 'settings/general',
    element: <GeneralSettings />,
  },
  {
    key: 'ManageUsers',
    path: 'settings/manage-users',
    element: <ManageUsersPage />,
  },
  {
    key: 'ManageAccounts',
    path: 'manage-accounts',
    element: <ManageAccounts />,
  },
  {
    key: 'ManageRecommendations',
    path: 'manage-recommendations',
    element: <ManageRecommendations />,
  },
  {
    key: 'ManageInsights',
    path: 'manage-insights',
    element: <InsightsManagement />,
  },
  {
    key: 'ManageVendors',
    path: 'manage-vendors',
    element: <ManageVendors />,
  },
  {
    key: 'ManageReports',
    path: 'manage-reports',
    element: <ManageReports />,
  },
  {
    key: 'DataConnectors',
    path: 'data-connectors',
    element: <DataConnectors />,
  },
  {
    key: 'ManageRegulations',
    path: 'manage-regulations',
    element: <ManageRegulations />,
  },
  {
    key: 'ManageStories',
    path: 'manage-stories',
    element: <ManageStories />,
  },
  {
    key: 'ManageAccounts',
    path: 'impersonate',
    element: <ImpersonateUserPage />,
  },
  {
    key: 'ManageSettings',
    path: 'manage-settings',
    element: <ManageSettings />,
  },
  {
    key: 'ManageGroups',
    path: 'settings/manage-groups',
    element: <ManageGroupsPage />,
  },
  {
    key: 'Messaging',
    path: 'messaging',
    element: <MessagingPage />,
  },
  {
    key: 'SubscriptionTypes',
    path: 'subscription-types',
    element: <SubscriptionTypes />,
  },
];
