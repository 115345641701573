import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useAssignRecommendation } from '@app/api/recommendation.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { Select, Spin } from 'antd';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useGetModelItems } from '@app/api/table.api';
import { getEnumValue } from '@app/services/enum.service';
import { TUsers } from '@app/types/users';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { useGetManyRecommendationExtended } from './hooks/useGetRecommendationExtended';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalMessage = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: #272727;
`;

const FieldLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;
const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 60px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const HorizontalAlignedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 1rem;
  font-weight: bold;
`;

export type TAssignRecommendationForm = {
  assignedToId: number;
  notes: string;
};

interface IAssignRecommendationModalProps extends ModalFuncProps {
  recommendationIds: number[];
  recommendations?: TAccountRecommendationExtended[];
}

export default function AssignRecommendationModal({
  recommendationIds,
  recommendations,
  ...modalProps
}: IAssignRecommendationModalProps) {
  const { recommendations: fetchedRecommendations, isLoading } = useGetManyRecommendationExtended({
    recommendationIds,
    recommendationsProp: recommendations,
  });

  const { mutateAsync: assignRecommendation, isLoading: isAssigning } = useAssignRecommendation();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<TAssignRecommendationForm>({
    mode: 'onBlur',
  });

  const { data: userResponse } = useGetModelItems<TUsers>({
    model: 'user',
    queryParams: `$filter=(status eq ${getEnumValue('UserStatus', 'Active')})`,
  });

  const userOptions = useMemo(() => {
    if (!userResponse)
      return [
        {
          label: 'Unassign',
          value: -1,
        },
      ];

    const userResponseOptions = userResponse.items
      .map((user) => ({
        label: user.email,
        value: user.id,
      }))
      .sort((a, b) => (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase()));

    userResponseOptions.unshift({
      label: 'Unassign',
      value: -1,
    });

    return userResponseOptions;
  }, [userResponse]);

  useEffect(() => {
    const assignedToId = recommendations?.[0]?.assignedToId;

    reset({
      assignedToId: assignedToId || undefined,
    });
  }, [modalProps.open, recommendations]);

  const handleAssign: SubmitHandler<TAssignRecommendationForm> = async (data) => {
    const recommendationIds = fetchedRecommendations.map((recommendation) => recommendation.recommendationId);

    const response = await assignRecommendation({
      assignedToId: data.assignedToId,
      notes: data.notes,
      recommendationIds,
    });

    if (response.status === 201) {
      reset();
      modalProps.onOk?.();
    }
  };

  const handleCloseModal = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={fetchedRecommendations.length > 1 ? 'Recommendations Assignment' : 'Recommendation Assignment'}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      footer={null}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <FieldContainer>
            <ModalMessage>
              {fetchedRecommendations.length > 1
                ? `${fetchedRecommendations.length} recommendations selected.`
                : fetchedRecommendations[0]?.findings}
            </ModalMessage>
          </FieldContainer>

          <FormContainer onSubmit={handleSubmit(handleAssign)}>
            <FieldContainer>
              <FieldLabel>Assign this recommendation as a task for a user in your team:</FieldLabel>
              <InputContainer>
                <Controller
                  control={control}
                  name="assignedToId"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      allowClear
                      value={field.value}
                      showSearch
                      style={{ flexBasis: '75%' }}
                      options={userOptions}
                      optionFilterProp="children"
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
                    >
                      {userOptions.map((opt) => (
                        <Select.Option key={opt.value} value={opt.value}>
                          {opt.label}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                />
                {!!errors.assignedToId && <ErrorMessage>This field is required</ErrorMessage>}
              </InputContainer>
            </FieldContainer>
            <FieldContainer>
              <FieldLabel>Notes:</FieldLabel>
              <InputContainer>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <HtmlEditor initialValue={field.value} onChange={(content) => setValue('notes', content)} />
                  )}
                />
              </InputContainer>
            </FieldContainer>
            <HorizontalAlignedContainer
              style={{
                gap: '10px',
                justifyContent: 'center',
                marginTop: '24px',
              }}
            >
              <CancelButton type="button" onClick={modalProps.onCancel}>
                Cancel
              </CancelButton>
              <ApplyButton type="submit" disabled={isAssigning}>
                Assign
              </ApplyButton>
            </HorizontalAlignedContainer>
          </FormContainer>
        </>
      )}
    </Modal>
  );
}
