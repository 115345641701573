import { useState } from 'react';
import { Spin, Radio, Popover, Typography } from 'antd';
import styled from 'styled-components';
import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';

import EmailGroupInput from './EmailGroupInput/EmailGroupInput';
import { useEmailGroupInput } from './EmailGroupInput/useEmailGroupInput';
import { useInviteUsersWithGroup } from '@app/api/user.api';
import { SemiCirleCheckIcon } from '@app/components/icons';
import { CenterContainer, VerticalContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { CaretDownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { TGroupExtended } from '@app/types/groupExtended';
import { Dropdown, Space } from 'antd';
import type { MenuProps, RadioChangeEvent } from 'antd';
import { getEnumValue } from '@app/services/enum.service';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

const FieldLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #272727;
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;
const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 60px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
`;

const Message = styled.span`
  font-size: 32px;
  font-weight: 800;
  line-height: 50px;
  color: #272727;
  text-align: center;
`;

const DropdownContainer = styled.div`
  width: 100%;
  padding: 8px;
  border: 1px solid #e9eaeb;
  border-radius: 10px;
`;

const StyledSpace = styled(Space)`
  width: 100%;
  justify-content: space-between;
`;

interface IModalFooterProps {
  onCancel?: () => void;
  onApply?: () => void;
  onApplyDisabled?: boolean;
}

const ModalFooter = ({ onCancel, onApply, onApplyDisabled }: IModalFooterProps) => {
  return (
    <FooterContainer>
      <CancelButton onClick={() => onCancel?.()}>Cancel</CancelButton>
      <ApplyButton disabled={onApplyDisabled} onClick={() => onApply?.()}>
        Invite
      </ApplyButton>
    </FooterContainer>
  );
};

interface IInviteUsersModalProps extends ModalFuncProps {
  isLoading?: boolean;
  onInviteSuccess?: () => void;
  groups?: TGroupExtended[];
}

export default function InviteUsersModal({ onInviteSuccess, groups, ...modalProps }: IInviteUsersModalProps) {
  const { mutateAsync: inviteUsersWithGroup, isLoading: isInviteUsersLoading } = useInviteUsersWithGroup();
  const [isSuccess, setIsSuccess] = useState(false);
  const emailGroupInputProps = useEmailGroupInput();
  const [role, setRole] = useState(2);
  const [selectedGroup, setSelectedGroup] = useState<TGroupExtended | null>(null);
  const [isAdminRole, setIsAdminRole] = useState(false);
  const { Text } = Typography;

  const items: MenuProps['items'] = groups?.map((g) => ({
    label: g.name,
    key: g.id,
  }));

  const onClick = (e: any) => {
    const group = groups?.find((g) => g.id.toString() === e.key);
    setSelectedGroup(group || null);
  };

  const handleInviteUsers = async () => {
    const invites = emailGroupInputProps.emails.map((email) => ({
      email,
      role,
    }));

    await inviteUsersWithGroup({
      invites,
      groupId: role === getEnumValue('UserRole', 'Admin') ? null : Number(selectedGroup?.id),
    });

    setIsSuccess(true);
    onInviteSuccess?.();
  };

  const handleCloseModal = () => {
    setIsSuccess(false);
    setRole(2);
    setIsAdminRole(false);
    setSelectedGroup(null);
    emailGroupInputProps.clearEmails();
    modalProps.onCancel?.();
  };

  const handleRoleChange = (e: RadioChangeEvent) => {
    setRole(e.target.value);
    setIsAdminRole(e.target.value === 1);
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={!isInviteUsersLoading && isSuccess ? null : 'Invite Users'}
      destroyOnClose={true}
      closable={false}
      onCancel={handleCloseModal}
      footer={
        isInviteUsersLoading ? (
          <FooterContainer>
            <Spin />
          </FooterContainer>
        ) : isSuccess ? (
          <FooterContainer>
            <ApplyButton onClick={handleCloseModal}>Continue</ApplyButton>
          </FooterContainer>
        ) : (
          <ModalFooter
            onCancel={handleCloseModal}
            onApply={handleInviteUsers}
            onApplyDisabled={emailGroupInputProps.emails.length === 0}
          />
        )
      }
    >
      {isSuccess ? (
        <CenterContainer
          style={{
            gap: '16px',
            marginTop: '60px',
          }}
        >
          <SemiCirleCheckIcon />
          <Message>Users Are Invited!</Message>
        </CenterContainer>
      ) : (
        <Container>
          <FieldContainer>
            <FieldLabel>User Email Addresses:</FieldLabel>
            <EmailGroupInput {...emailGroupInputProps} />
          </FieldContainer>

          <FieldContainer>
            <FieldLabel>Assigned Role For New Users:</FieldLabel>
            <Radio.Group name="radiogroup" value={role}>
              <Radio value={1} onChange={handleRoleChange}>
                <VerticalContainer>
                  Administrator
                  <Text type="secondary" style={{ fontSize: '14px' }}>
                    Users with this role have the highest permissions level and can perform all actions, including
                    account-level settings configurations
                  </Text>
                </VerticalContainer>
              </Radio>

              <Radio value={2} onChange={handleRoleChange}>
                <VerticalContainer>
                  User
                  <Text type="secondary" style={{ fontSize: '14px' }}>
                    Permissions for this role are defined according to the permissions assigned to the user’s group
                  </Text>
                </VerticalContainer>
              </Radio>
            </Radio.Group>
          </FieldContainer>

          {isAdminRole ? null : (
            <FieldContainer>
              <FieldLabel>Assigned Group For New Users:</FieldLabel>
              <DropdownContainer>
                <Dropdown menu={{ items, onClick }} placement="bottomLeft" trigger={['click']}>
                  <StyledSpace>
                    {selectedGroup ? selectedGroup.name : 'No Group'} <CaretDownOutlined />
                  </StyledSpace>
                </Dropdown>
              </DropdownContainer>
            </FieldContainer>
          )}
        </Container>
      )}
    </Modal>
  );
}
