import { getEnumValue } from '@app/services/enum.service';
import { useAppSelector } from './reduxHooks';

export const useHasActionPermission = (
  action:
    | 'Assign Recommendation'
    | 'Update Recommendation Compliance'
    | 'Exempt Recommendation'
    | 'View Recommendation'
    | 'Edit Alerts',
) => {
  const userRole = useAppSelector((state) => state.user.user?.role);
  const adminRole = getEnumValue('UserRole', 'Admin');
  const actionPermissions = useAppSelector((state) => state.app.allowedScreensAndActions?.actions);

  if (userRole === adminRole) {
    return true;
  }

  if (action === 'View Recommendation') return !!actionPermissions?.find((act) => act.name === 'viewRecommendations');
  if (action === 'Update Recommendation Compliance')
    return !!actionPermissions?.find((act) => act.name === 'updateRecommendationCompliance');
  if (action === 'Exempt Recommendation')
    return !!actionPermissions?.find((act) => act.name === 'exemptRecommendations');
  if (action === 'Assign Recommendation')
    return !!actionPermissions?.find((act) => act.name === 'assignRecommendations');
  if (action === 'Edit Alerts') return !!actionPermissions?.find((act) => act.name === 'editAlerts');

  return false;
};
