import { TMessageTemplateForm } from '@app/components/apps/messaging/MessagingForm';
import { useMutation, useQueryClient } from 'react-query';
import { httpApi } from './http.api';
import { notificationController } from '@app/controllers/notificationController';

type TUpdateMessageTemplateVariables = {
  id: number;
  payload: Pick<TMessageTemplateForm, 'title' | 'content'>;
};

const updateMessageTemplate = async ({ id, payload }: TUpdateMessageTemplateVariables) => {
  const response = await httpApi.patch(`/message-template/${id}`, payload);
  return response?.data;
};

export const useUpdateMessageTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateMessageTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries('MessageTemplate-odata');
      notificationController.success({
        message: 'Message template updated',
      });
    },
    onError: () => {
      notificationController.error({
        message: 'Something went wrong',
      });
    },
  });
};
