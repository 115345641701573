import { useTranslation } from 'react-i18next';

import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { TAccountRegulations } from '@app/types/accountRegulations';
import ComplianceCard from '@app/components/apps/regulations-compliance/ComplianceCard';
import { useGetModelItems } from '@app/api/table.api';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: 1fr;
  column-gap: 6px;
  row-gap: 10px;
`;

export default function RegulationsCompliancePage() {
  const { data } = useGetModelItems<TAccountRegulations>({
    model: 'AccountRegulations',
    queryParams: `$filter=(status eq 1)`,
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCardClick = (id: number) => {
    navigate(`/regulation-information/${id}`);
  };

  return (
    <>
      <PageTitle>{t('common.regulationsCompliance')}</PageTitle>
      <ComponentHeader
        title={t('common.regulationsCompliance')}
        subTitle="Below is a summary of your environmental adherence to the set of standard regulations in the field of security posture."
        subTitleFullWidth={true}
      />
      <CardsContainer>
        {data?.items.map((item) => (
          <ComplianceCard
            key={item.id}
            {...{
              ...item,
              numOfRecommendations: parseInt(item.numOfRecommendations),
              numOfCompliant: parseInt(item.numOfCompliant),
            }}
            onCardClick={() => handleCardClick(item.id)}
          />
        ))}
      </CardsContainer>
    </>
  );
}
