import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import EventDetailsModal from '@app/components/apps/change-logs/EventDetailsModal';
import { AllHistory } from '@app/components/apps/recommendation-history/AllHistory';
import { Assignments } from '@app/components/apps/recommendation-history/Assignments';
import { ConfigurationChanges } from '@app/components/apps/recommendation-history/ConfigurationChanges';
import { Exemptions } from '@app/components/apps/recommendation-history/Exemptions';
import RecommendationDetailsModal from '@app/components/apps/recommendations/RecommendationDetailsModal';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import SeverityBadge from '@app/components/tables/AntdTableWrapper/components/SeverityBadge';
import {
  ChangeTypeBadge,
  ComplianceStatusBadge,
  ExemptionStatusBadge,
} from '@app/components/tables/AntdTableWrapper/components/StatusBadge';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { TRecommendationHistory } from '@app/types/recommendationHistory';
import { Popover, Tabs } from 'antd';
import { format, parse } from 'date-fns';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import CloudSyncIcon from '@app/components/icons/cloud-sync/CloudSync';
import { useRecommendationTableColumnsAndActions } from '@app/components/apps/recommendations/hooks/useRecommendationTableCols';
import AssignRecommendationModal from '@app/components/apps/recommendations/AssignRecommendationModal';
import ExemptRecommendationModal from '@app/components/apps/recommendations/ExemptRecommendationModal';
import MarkComplianceModal from '@app/components/apps/recommendations/MarkComplianceModal';

export default function RecommendationHistory() {
  const { t } = useTranslation();
  const productOptions =
    useAppSelector((state) => state.app.products)?.map((opt) => ({
      key: opt.displayName,
      value: opt.displayName,
    })) || [];

  const {
    actionItems,
    selectedRows,
    selectedRecommendation,
    complianceAction,
    isAdditionalDetailsModalOpen,
    isAssignRecommendationModalOpen,
    isExemptModalOpen,
    isMarkComplianceModalOpen,
    setSelectedRows,
    handleOpenAdditionalDetailsModal,
    toggleAdditionalDetailsModal,
    toggleAssignModalOpen,
    toggleComplianceModal,
    toggleExemptModal,
  } = useRecommendationTableColumnsAndActions({
    onUnExempt: (items) => {
      activeDataTableProps?.refetch();
      activeDataTableProps?.clearSelection();
    },
  });

  const [hoveredDate, setHoveredDate] = useState<string | undefined>();
  const [eventDetailsModalOpen, setEventDetailsModalOpen] = useState(false);
  const [changeLog, setChangeLog] = useState<{ changeLogDate: string; eventsText: string } | undefined>();
  const [timelineRange, setTimelineRange] = useState<{ startDate: Date; endDate: Date } | undefined>({
    startDate: moment(new Date()).subtract(90, 'days').toDate(),
    endDate: moment(new Date()).toDate(),
  });

  const [activeTabKey, setActiveTabKey] = useState('1');
  const [activeDataTableProps, setActiveDataTableProps] = useState<
    ReturnType<typeof useDataTable<TRecommendationHistory>> | undefined
  >();

  const handleAdditionalDetailsClicked = (props: { changeLogDate: string; eventsText: string }) => {
    setChangeLog(props);
    setEventDetailsModalOpen(true);
  };

  const handleAdditionalDetailsModalClose = () => {
    setEventDetailsModalOpen(false);
    setChangeLog(undefined);
  };

  const handleOnRowClick = (row: TRecommendationHistory) => {
    handleOpenAdditionalDetailsModal(row as TRecommendationHistory & TAccountRecommendationExtended);
  };

  const tableCols: TDataColumns<TRecommendationHistory & TAccountRecommendationExtended> = [
    {
      title: 'Time',
      dataIndex: 'date',
      allowFiltering: true,
      type: 'datetime',
      render(value, record) {
        return <>{format(parse(record.date as any, 'dd/MM/yyyy HH:mm:ss', new Date()), 'dd/MM/yyyy HH:mm')}</>;
      },
      showSortDirections: true,
      width: '10vw',
    },
    {
      title: 'Findings',
      dataIndex: 'findings',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '16vw',
      // render: (_, record) => {
      //   if (parseInt(record.status) != getEnumValue('RecommendationStatus', 'Enabled')) {
      //     return record.findings;
      //   }

      //   return (
      //     <Link to={`/recommendations/${record.recommendationId}`} target="_blank">
      //       {record.findings}
      //     </Link>
      //   );
      // },
    },
    {
      title: 'Change Type',
      dataIndex: 'changeType',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      align: 'center',
      width: '8vw',
      render: (_, record) => {
        return <ChangeTypeBadge type={record.changeType as any}>{record.changeType}</ChangeTypeBadge>;
      },
    },
    {
      title: 'Change Description',
      dataIndex: 'changeDescription',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '16vw',
    },
    {
      title: 'Change Field',
      dataIndex: 'changeField',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      width: '8vw',
      hideColumn: true,
    },
    {
      title: 'Snapshot Type',
      dataIndex: 'snapshotType',
      allowFiltering: false,
      showSortDirections: true,
      width: '8vw',
      allowDisplay: false,
      hideColumn: true,
    },
    // {
    //   title: 'Score',
    //   dataIndex: 'scoreValue',
    //   allowFiltering: true,
    //   type: 'number',
    //   showSortDirections: true,
    //   render(value, record) {
    //     return <>{`${record.scoreValue} pts`}</>;
    //   },
    //   width: '8vw',
    //   align: 'center',
    // },
    {
      title: 'Product',
      dataIndex: 'productName',
      align: 'center',
      allowFiltering: true,
      type: 'textOptions',
      options: productOptions,
      multipleSelect: true,
      showSortDirections: true,
      width: '8vw',
      // render(_, record) {
      //   const productName = getProductLabel(record.productName);
      //   return <>{productName || record.productName}</>;
      // },
    },
    {
      title: 'Severity',
      dataIndex: 'severity',
      align: 'center',
      allowFiltering: true,
      type: 'enum',
      enumValuesKey: 'RecommendationSeverity',
      showSortDirections: true,
      width: '6vw',
      hideColumn: true,

      render: (_, record) => {
        return (
          <SeverityBadge type={(record.severity as unknown as string).toLowerCase() as any}>
            {record.severity}
          </SeverityBadge>
        );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      allowFiltering: true,
      type: 'text',
      showSortDirections: true,
      hideColumn: true,
    },
    {
      title: () => {
        return (
          <Popover content={<div style={{ maxWidth: '5vw', textAlign: 'center' }}>Validation Method</div>}>
            <div>
              <CloudSyncIcon style={{ height: '32px', width: '32px' }} />
            </div>
          </Popover>
        );
      },
      altLabel: 'Validation Method',
      dataIndex: 'validationMethod',
      allowFiltering: true,
      showSorterTooltip: false,
      type: 'enum',
      align: 'center',
      enumValuesKey: 'RecommendationValidationMethod',
      showSortDirections: false,
      width: '5vw',
    },
    {
      title: 'Compliance',
      dataIndex: 'isCompliant',
      allowFiltering: true,
      type: 'enum',
      hideColumn: true,
      enumValuesKey: 'BooleanEnum',
      showSortDirections: true,
      width: '5vw',
      align: 'center',
      render(_, record) {
        if (record.isCompliant == null) return 'N/A';

        return (
          <ComplianceStatusBadge isCompliant={record.isCompliant as boolean}>
            {record.isCompliant ? 'Compliant' : 'Non-Compliant'}
          </ComplianceStatusBadge>
        );
      },
    },
    {
      title: 'Exemption',
      dataIndex: 'isExempted',
      allowFiltering: true,
      type: 'boolean',
      hideColumn: true,
      showSortDirections: true,
      width: '4vw',
      align: 'center',
      render(_, record) {
        const isExempted = record.isExempted == 'True';
        return isExempted ? <ExemptionStatusBadge isExempted={isExempted}>Exempted</ExemptionStatusBadge> : 'None';
      },
    },
  ];

  const tabs = [
    {
      label: 'All History',
      key: '1',
      children: (
        <AllHistory
          actionItems={actionItems as any}
          tableColumns={tableCols as any}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
          onRowClickCallback={handleOnRowClick}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      label: 'Configuration Changes',
      key: '2',
      children: (
        <ConfigurationChanges
          tableColumns={tableCols as any}
          actionItems={actionItems as any}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
          onRowClickCallback={handleOnRowClick}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      label: 'Assignments',
      key: '3',
      children: (
        <Assignments
          tableColumns={tableCols as any}
          actionItems={actionItems as any}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
          onRowClickCallback={handleOnRowClick}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      label: 'Exemptions',
      key: '4',
      children: (
        <Exemptions
          tableColumns={tableCols as any}
          actionItems={actionItems as any}
          timelineRange={timelineRange}
          setTimelineRange={setTimelineRange}
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          setActiveDataTableProps={setActiveDataTableProps}
          handleAdditionalDetailsClicked={handleAdditionalDetailsClicked}
          onRowClickCallback={handleOnRowClick}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
  ];

  useEffect(() => {
    if (!timelineRange) return;

    activeDataTableProps?.refetch();
  }, [timelineRange]);

  return (
    <>
      <PageTitle>Recommendation History</PageTitle>
      <ComponentHeader
        title={t('common.recommendationHistory')}
        subTitle="View the history of security recommendation changes, including compliance shifts and exemptions, to understand the evolution of your organization's security posture. Track the dates and details of each state change for comprehensive auditing."
        subTitleFullWidth={true}
      />
      <Tabs
        activeKey={activeTabKey}
        items={tabs}
        onChange={(activeKey) => setActiveTabKey(activeKey)}
        destroyInactiveTabPane={true}
      />
      {eventDetailsModalOpen && changeLog && (
        <EventDetailsModal {...changeLog} open={true} onCancel={handleAdditionalDetailsModalClose} />
      )}
      {isAdditionalDetailsModalOpen && selectedRecommendation && (
        <RecommendationDetailsModal
          open={true}
          recommendationId={selectedRecommendation.recommendationId}
          onCancel={() => {
            toggleAdditionalDetailsModal(false);
          }}
        />
      )}

      {isExemptModalOpen && selectedRows.length > 0 && (
        <ExemptRecommendationModal
          recommendationIds={selectedRows.map((row) => row.recommendationId)}
          open={isExemptModalOpen}
          onCancel={() => toggleExemptModal(false)}
          onOk={() => {
            toggleExemptModal(false);
            activeDataTableProps?.refetch();
            activeDataTableProps?.clearSelection();
          }}
        />
      )}

      {isMarkComplianceModalOpen && selectedRows.length > 0 && (
        <MarkComplianceModal
          recommendationIds={selectedRows.map((row) => row.recommendationId)}
          open={isMarkComplianceModalOpen}
          complianceAction={complianceAction}
          onCancel={() => toggleComplianceModal(false)}
          onOk={() => {
            toggleComplianceModal(false);
            activeDataTableProps?.clearSelection();
            activeDataTableProps?.refetch();
          }}
        />
      )}

      {isAssignRecommendationModalOpen && selectedRows.length > 0 && (
        <AssignRecommendationModal
          recommendationIds={selectedRows.map((row) => row.recommendationId)}
          recommendations={selectedRows}
          open={isAssignRecommendationModalOpen}
          onCancel={() => toggleAssignModalOpen(false)}
          onOk={() => {
            toggleAssignModalOpen(false);
            activeDataTableProps?.clearSelection();
            activeDataTableProps?.refetch();
          }}
        />
      )}
    </>
  );
}
