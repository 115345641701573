import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import { TRecommendationForm } from '../RecommendationForm';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 35%;
  flex-shrink: 0;
`;

interface RemediationStepsFieldsProps {
  control: Control<TRecommendationForm>;
  setValue: UseFormSetValue<TRecommendationForm>;
}

export default function RemediationStepsFields({ control, setValue }: RemediationStepsFieldsProps) {
  return (
    <FormContainer>
      <HorizontalAlignedContainer>
        <FieldLabel>Remediation:</FieldLabel>
        <Controller
          control={control}
          name="remediation"
          render={({ field }) => (
            <HtmlEditor initialValue={field.value} onChange={(content) => setValue('remediation', content)} />
          )}
        />
      </HorizontalAlignedContainer>
    </FormContainer>
  );
}
