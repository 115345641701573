import { Editor } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FaHighlighter } from 'react-icons/fa6';
import { TbHighlightOff } from 'react-icons/tb';
import { MdFormatColorText } from 'react-icons/md';

const colors = [
  '#404040',
  '#666666',
  '#999999',
  '#b7b7b7',
  '#cccccc',
  '#ff0000',
  '#cc0000',
  '#e06666',
  '#ea9999',
  '#f4cccc',
  '#ffff00',
  '#f1c232',
  '#ffd966',
  '#ffe599',
  '#fff2cc',
  '#00ff00',
  '#6aa84f',
  '#93c47d',
  '#b6d7a8',
  '#d9ead3',
  '#00ffff',
  '#4a86e8',
  '#0000ff',
  '#9900ff',
  '#ff00ff',
];

const ColorMenuDropdown = styled.div`
  position: relative;
`;

const ColorOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 8px;
  padding: 8px;
`;

const ColorOptionsContainer = styled.div<{ showDropdown?: boolean }>`
  display: ${(props) => (props.showDropdown ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  width: fit-content;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
`;

const RemoveHighlight = styled.div`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #666666;

  &:hover {
    background-color: #f3f4f6;
  }

  svg {
    margin-right: 8px;
  }
`;

const ColorButton = styled.div<{ isActive?: boolean; color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  border: 2px solid ${(props) => (props.isActive ? '#000' : 'transparent')};

  &:hover {
    border: 2px solid #000;
  }
`;

const IconWrapper = styled.button<{ showDropdown: boolean }>`
  background-color: ${(props) => (props.showDropdown ? 'lightgray' : 'transparent')};
  border: none;
  border-radius: 6px;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: lightgray;
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
`;

const ColorPicker = ({ editor, isTextHighlight }: { editor: Editor; isTextHighlight?: boolean }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [dropdownRef]);

  const applyHighlight = (color: string) => {
    editor.chain().focus().setHighlight({ color }).run();
    setShowDropdown(false);
  };

  const removeHighlight = () => {
    editor.chain().focus().unsetHighlight().run();
    setShowDropdown(false);
  };

  const applyTextColor = (color: string) => {
    editor.chain().focus().setColor(color).run();
    setShowDropdown(false);
  };

  const isActive = isTextHighlight ? editor.isActive('highlight') : editor.isActive('textStyle', { color: true });
  const activeColor = isTextHighlight
    ? editor.getAttributes('highlight').color || 'transparent'
    : editor.getAttributes('textStyle').color || '#404040';

  return (
    <ColorMenuDropdown ref={dropdownRef}>
      <IconWrapper type="button" onClick={() => setShowDropdown(!showDropdown)} showDropdown={showDropdown}>
        {isTextHighlight ? (
          <FaHighlighter
            title="Highlight color"
            type="button"
            size={20}
            style={{ color: isActive ? activeColor : 'black' }}
          />
        ) : (
          <MdFormatColorText
            title="Text color"
            type="button"
            size={20}
            style={{ color: isActive ? '#404040' : activeColor }}
          />
        )}
      </IconWrapper>
      <ColorOptionsContainer showDropdown={showDropdown}>
        {isTextHighlight && (
          <RemoveHighlight onClick={removeHighlight}>
            <TbHighlightOff size={20} style={{ color: 'black' }} />
            None
          </RemoveHighlight>
        )}
        <ColorOptions>
          {colors.map((color, index) => (
            <ColorButton
              key={index}
              color={color}
              isActive={activeColor === color}
              onClick={() => (isTextHighlight ? applyHighlight(color) : applyTextColor(color))}
            />
          ))}
        </ColorOptions>
      </ColorOptionsContainer>
    </ColorMenuDropdown>
  );
};

export default ColorPicker;
