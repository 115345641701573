import { useEffect, useMemo, useState } from 'react';
import { TMessageTemplate } from '@app/types/messaging';
import { Modal, ModalFuncProps, Spin, Typography } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';

const StyledModal = styled(Modal)`
  min-width: 800px;
`;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const FormTitle = styled.div`
  font-weight: 800;
  font-size: 20px;
  line-height: 23px;
  color: #272727;
  margin-bottom: 24px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 17px;
  color: gray;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #272727;
  margin-top: 24px;
`;

const FieldInput = styled.input<{ hasError?: boolean }>`
  flex-basis: 75%;
  background-color: transparent;
  border: ${(props) => (props.hasError ? '1px solid red' : '1px solid #acacac')};
  border-radius: 10px;
  padding: 12px;
  outline: none;
`;

const CancelButton = styled.button`
  border: 1px solid #272727;
  border-radius: 10px;
  background-color: transparent;
  padding: 12px 70px;
  color: #272727;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const EditButton = styled.button`
  border: 1px solid #3a50d1;
  border-radius: 10px;
  background-color: #3a50d1;
  padding: 12px 70px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const StyledButtonsContainer = styled(HorizontalAlignedContainer)`
  gap: 10px;
  justify-content: center;
`;

export type TMessageTemplateForm = {
  id: string | number;
  name: string;
  displayName: string;
  description?: string;
  title: string;
  content: string;
  status: number;
  creationTime: Date;
  lastUpdateTime: Date;
};

interface IMessagingFormProps extends ModalFuncProps {
  isEdit?: boolean;
  isLoading?: boolean;
  messageTemplate?: TMessageTemplate;
}

const MessagingForm = ({ isEdit, isLoading, messageTemplate, ...modalProps }: IMessagingFormProps) => {
  const { Text } = Typography;
  const { handleSubmit, register, reset, control, setValue } = useForm<TMessageTemplateForm>({
    mode: 'onBlur',
    defaultValues: {
      title: messageTemplate?.title,
      content: messageTemplate?.content,
    },
  });

  useEffect(() => {
    reset(messageTemplate);
  }, [messageTemplate, reset]);

  const onSubmit = (data: TMessageTemplateForm) => {
    modalProps.onOk?.(data);
  };

  const handleCancel = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <StyledModal {...modalProps} destroyOnClose closable={false} footer={null}>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>Edit Template</FormTitle>
        <FormContainer>
          <Col>
            <Label>Template Name:</Label>
            <Text>{messageTemplate?.displayName}</Text>
            <Label>Title:</Label>
            <FieldInput {...register('title')} />
            <Label>Content:</Label>
            <Controller
              control={control}
              name="content"
              render={({ field }) => (
                <HtmlEditor initialValue={field.value} onChange={(content) => setValue('content', content)} />
              )}
            />
          </Col>
        </FormContainer>
        <StyledButtonsContainer>
          {isLoading ? (
            <Spin />
          ) : (
            <>
              <CancelButton type="button" onClick={handleCancel}>
                Cancel
              </CancelButton>
              <EditButton>Update</EditButton>
            </>
          )}
        </StyledButtonsContainer>
      </Container>
    </StyledModal>
  );
};

export default MessagingForm;
