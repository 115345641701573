const SESSION_TOKEN_KEY = 'sessionToken';
const IMPERSONATE_TOKEN_KEY = 'impersonateToken';

export const persistSessionToken = (token: string) => {
  localStorage.setItem(SESSION_TOKEN_KEY, token);
};

export const persistImpersonationToken = (token: string) => {
  sessionStorage.setItem(IMPERSONATE_TOKEN_KEY, token);
};

export const getImpersonationToken = (): string | null => {
  return sessionStorage.getItem(IMPERSONATE_TOKEN_KEY);
};

export const readToken = (): string | null => {
  return localStorage.getItem(SESSION_TOKEN_KEY) || sessionStorage.getItem(IMPERSONATE_TOKEN_KEY);
};

export const deleteToken = () => localStorage.removeItem(SESSION_TOKEN_KEY);
