import { CloseOutlined } from '@ant-design/icons';
import { Modal, ModalFuncProps, Spin } from 'antd';
import { useMemo, useState } from 'react';
import { MdOutlineAutoStories, MdOutlineSecurity } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  CenterContainer,
  HorizontalAlignedContainer,
  VerticalContainer,
} from '@app/components/common/BaseLayout/BaseLayout.styled';
import Tabs from '@app/components/common/Tabs/Tabs';

import { useAddRecommendationComment, useGetACcountRecommendationHistory } from '@app/api/recommendation.api';
import { useGetRecommendationStories } from '@app/api/story.api';
import { useGetModelItemsLazy } from '@app/api/table.api';
import { parseDbEnumArray } from '@app/components/common/common';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { getEnumLabel } from '@app/services/enum.service';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { TRecommendationEventType } from '@app/types/recommendationHistory';
import { TRecommendationPrerequisiteExtended } from '@app/types/recommendationPrerequisiteExtended';
import { TStoryRecommendationWithStory } from '@app/types/storyRecommendation';
import { useGetRecommendationExtended } from './hooks/useGetRecommendationExtended';
import { AssignementChanges } from './RecommendationDetailsModal/AssignmentChanges';
import { CommentBlock } from './RecommendationDetailsModal/CommentBlock';
import { ComplianceStatusChanges } from './RecommendationDetailsModal/ComplianceStatusChanges';
import { ExemptionChanges } from './RecommendationDetailsModal/ExemptionChanges';

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const HistoryEventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: min-content;
  max-height: 35vh;
  overflow-y: auto;
`;

const EventItemContainer = styled.div<{ isComment?: boolean }>`
  border-bottom: ${({ isComment }) => (isComment ? 'none' : '1px solid #dfdfdfba')};
  padding: 4px 0;

  &:last-child {
    border-bottom: none;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const HalfContainer = styled.div`
  flex-basis: 50%;
  flex-shrink: 0;
  max-width: 50%;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: #272727;
`;

const Value = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #272727;
`;

const RecommendationIcon = styled(MdOutlineSecurity)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const StoryIcon = styled(MdOutlineAutoStories)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const ItemContainer = styled(HorizontalAlignedContainer)`
  border-bottom: 1px solid #e9eaeb;
  padding-bottom: 15px;
  cursor: pointer;
`;

interface IRecommendationDetailsModalProps extends ModalFuncProps {
  // recommendation: TAccountRecommendationExtended;
  recommendationId: number;
  recommendationProp?: TAccountRecommendationExtended;
  open: boolean;
  onClose?: () => void;
}

export default function RecommendationDetailsModal({
  recommendationId,
  recommendationProp,
  ...modalProps
}: IRecommendationDetailsModalProps) {
  const { recommendation, isLoading } = useGetRecommendationExtended({
    recommendationId,
    recommendationProp,
  });

  const [activeTab, setActiveTab] = useState(0);

  const { data: storyRecommendationResponse } = useGetRecommendationStories(recommendationId);

  const recommendationStories = useMemo(() => {
    if (!storyRecommendationResponse) return [];

    return storyRecommendationResponse;
  }, [storyRecommendationResponse]);

  const tabContent = useMemo(() => {
    if (!recommendation) return [];

    return [
      {
        title: 'Overview',
        content: <OverviewSection recommendation={recommendation} recommendationStories={recommendationStories} />,
        disabled: false,
      },
      {
        title: 'Requirements',
        content: <PreRequisitesSection recommendation={recommendation} activeTab={activeTab} />,
        disabled: false,
      },
      {
        title: 'Remediation steps',
        content: <RemediationSection {...recommendation} />,
        disabled: false,
      },
      {
        title: 'History',
        content: (
          <History
            recommendationId={recommendationId.toString()}
            recommendationValidationMethod={recommendation?.validationMethod}
            accountRecommendationId={recommendation.id}
          />
        ),
        disabled: false,
      },
    ];
  }, [recommendation, activeTab]);

  return (
    <Modal
      title={recommendation?.findings}
      {...modalProps}
      destroyOnClose={true}
      closeIcon={<CloseOutlined style={{ color: '#D81C2E', marginLeft: '12px' }} />}
      footer={null}
      width="80vw"
    >
      {isLoading ? (
        <CenterContainer>
          <Spin />
        </CenterContainer>
      ) : (
        <Tabs tabContent={tabContent} activeTab={activeTab} setActiveTab={setActiveTab} />
      )}
    </Modal>
  );
}

function OverviewSection({
  recommendation,
  recommendationStories,
}: {
  recommendation?: Partial<TAccountRecommendationExtended>;
  recommendationStories: TStoryRecommendationWithStory[];
}) {
  return (
    <ColumnsContainer>
      <Col>
        <Label>Risk description: </Label>
        {recommendation?.description && <HtmlEditor initialValue={recommendation.description} readOnly />}
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Corrective action: </Label>
            <Value>{recommendation?.correctiveAction}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Compliant: </Label>
            <Value>{recommendation?.isCompliant == null ? 'N/A' : recommendation?.isCompliant ? 'Yes' : 'No'}</Value>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Exempted: </Label>
            <Value>{!!!recommendation?.isExempted || recommendation?.isExempted === 'False' ? 'No' : 'Yes'}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Product: </Label>
            <Value>{recommendation?.productName}</Value>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Assigned to: </Label>
            <Value>{recommendation?.assigneeEmail || 'Unassigned'}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Risk level: </Label>
            <Value>{recommendation?.severity}</Value>
          </HalfContainer>
        </Row>
      </Col>

      <Col>
        <Row>
          <HalfContainer>
            <Label>Estimated work: </Label>
            <Value>{recommendation?.easeOfFix}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>User impact: </Label>
            <Value>{recommendation?.easeOfFix}</Value>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Operating system: </Label>
            <Value>{recommendation?.operatingSystem}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Regulation: </Label>
            <Value>{''}</Value>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Scopes: </Label>
            <Value>
              {parseDbEnumArray(recommendation?.scopes || '')
                ?.map((scope) => getEnumLabel('RecommendationScope', scope))
                .join(', ')}
            </Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Reference: </Label>
            <Value>
              <a href={recommendation?.reference} target="_blank" rel="noreferrer">
                {recommendation?.reference && recommendation.reference.length > 50
                  ? `${recommendation.reference.substring(0, 50)}...`
                  : recommendation?.reference}
              </a>
            </Value>
          </HalfContainer>
        </Row>
      </Col>
      <Col>
        <Row>
          <HalfContainer>
            <Label>Tenant wide: </Label>
            <Value>{recommendation?.tenantWide ? 'Yes' : 'No'}</Value>
          </HalfContainer>
          <HalfContainer>
            <Label>Stories: </Label>
            <VerticalContainer>
              {recommendationStories.map((rs) => (
                <Link key={rs.id} to={`/story/${rs.storyId}`}>
                  {rs.story.name}
                </Link>
              ))}
            </VerticalContainer>
          </HalfContainer>
        </Row>
      </Col>
    </ColumnsContainer>
  );
}

function RemediationSection(recommendation?: Partial<TAccountRecommendationExtended>) {
  return (
    <ColumnsContainer>
      <Col>
        <Label>Remediation: </Label>
        {recommendation?.remediation && <HtmlEditor initialValue={recommendation.remediation} readOnly />}
      </Col>
    </ColumnsContainer>
  );
}

const prerequisiteCols: TDataColumns<TRecommendationPrerequisiteExtended> = [
  {
    title: 'Type',
    dataIndex: 'type',
    allowFiltering: true,
    type: 'text',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    allowFiltering: true,
    type: 'text',
  },
  {
    title: 'Percentage Compliant',
    dataIndex: 'percentageCompliant',
    allowFiltering: true,
    type: 'text',
  },
  {
    title: 'Compliant',
    dataIndex: 'compliant',
    allowFiltering: true,
    type: 'boolean',
  },
];

interface PrerequisitesSectionProps {
  activeTab: number;
  recommendation?: Partial<TAccountRecommendationExtended>;
}

function PreRequisitesSection({ recommendation, activeTab }: PrerequisitesSectionProps) {
  const { data: prerequisuites, isFetching } = useGetModelItemsLazy<TRecommendationPrerequisiteExtended>({
    model: 'recommendationprerequisiteextended',
    columns: prerequisiteCols,
    queryParams:
      activeTab === 1 && recommendation?.recommendationId
        ? `$filter=recommendationId eq ${recommendation.recommendationId}`
        : undefined,
  });

  return (
    <ColumnsContainer>
      <Col>
        <Label>User impact details: </Label>
        {recommendation?.prerequisiteDescription && (
          <HtmlEditor initialValue={recommendation?.prerequisiteDescription} readOnly />
        )}
      </Col>
      {recommendation?.prerequisiteReference && (
        <Col>
          <Label>Reference: </Label>
          <Value>
            <a href={recommendation?.prerequisiteReference} target="_blank" rel="noreferrer">
              {recommendation?.prerequisiteReference}
            </a>{' '}
          </Value>
        </Col>
      )}
      <Col>
        <Label>Licenses: </Label>
        <Value>{recommendation?.licenseNames || 'No license associated with this recommendation.'}</Value>
      </Col>
      <Col>
        <Label>License Plans: </Label>
        <Value>{recommendation?.licensePlans || 'No license plans associated with this recommendation.'}</Value>
      </Col>
      <Col>
        <Label>
          Related recommendations:
          {isFetching && <Spin style={{ marginLeft: '12px' }} />}
        </Label>

        {!isFetching && (
          <>
            {!!prerequisuites && prerequisuites.items.length > 0 ? (
              prerequisuites?.items.map((item) => (
                <ItemContainer
                  key={item.id}
                  onClick={() => {
                    const url =
                      item.type === 'recommendation'
                        ? `/recommendations/${item.prerequisiteRecommendationId}`
                        : `/story/${item.prerequisiteStoryId}`;
                    window.open(`${url}`, '_blank');
                  }}
                >
                  {item.type === 'recommendation' ? <RecommendationIcon /> : <StoryIcon />}

                  <span
                    style={{
                      flexGrow: 1,
                    }}
                  >
                    {item.name}
                  </span>
                  {item.type === 'recommendation' ? (
                    <span>{(item.compliant as any) === 'True' ? 'Compliant' : 'Non-Compliant'}</span>
                  ) : (
                    <span>{`${item.percentageCompliant}% Completed`}</span>
                  )}
                </ItemContainer>
              ))
            ) : (
              <Value>No related recommendations.</Value>
            )}
          </>
        )}
      </Col>
    </ColumnsContainer>
  );
}

export interface IEventItem {
  eventType: TRecommendationEventType;
  accountRecommendationId: string;
  recommendationValidationMethod: number;
  commentReadOnly?: boolean;
  title?: string;
  showTimeOnly?: boolean;
}

export function EventItem({
  eventType,
  accountRecommendationId,
  recommendationValidationMethod,
  commentReadOnly,
  title,
  showTimeOnly,
}: IEventItem) {
  if (eventType.event_type === 'IsCompliant') {
    return (
      <ComplianceStatusChanges
        event={eventType}
        recommendationValidationMethod={recommendationValidationMethod}
        title={title}
        showTimeOnly={showTimeOnly}
      />
    );
  }

  // Recommendation Exemptions
  if (eventType.event_type === 'IsExempted') {
    return <ExemptionChanges event={eventType} title={title} showTimeOnly={showTimeOnly} />;
  }

  // Recommendation Comments
  if (eventType.event_type === 'comment') {
    return (
      <CommentBlock
        accountRecommendationId={accountRecommendationId}
        event={eventType}
        readOnly={commentReadOnly}
        showTimeOnly={showTimeOnly}
      />
    );
  }

  return <AssignementChanges event={eventType} title={title} showTimeOnly={showTimeOnly} />;
}

function History({
  recommendationId,
  recommendationValidationMethod,
  accountRecommendationId,
}: {
  recommendationId: string;
  accountRecommendationId: string;
  recommendationValidationMethod: number;
}) {
  const { data: events } = useGetACcountRecommendationHistory(accountRecommendationId);

  const { mutateAsync: addComment, isLoading: isAddingComment } = useAddRecommendationComment(recommendationId);

  const handleAddComment = async (htmlString?: string) => {
    if (!htmlString) return;

    const response = await addComment({ accountRecommendationId: recommendationId, comment: htmlString });
  };

  return (
    <ColumnsContainer>
      <HistoryEventsContainer>
        {events && events.length > 0 ? (
          events.map((event, index) => (
            <EventItem
              key={event.id ? event.id : `${event.date}-${event.event_type}-${index}`}
              eventType={event}
              accountRecommendationId={recommendationId}
              recommendationValidationMethod={recommendationValidationMethod}
            />
          ))
        ) : (
          <Value>No data recorded for this recommendation so far.</Value>
        )}
      </HistoryEventsContainer>
      <Col>
        <Label>Add a comment: </Label>
        <HtmlEditor height={80} onSave={handleAddComment} showSaveSection={true} saveDisabled={isAddingComment} />
      </Col>
    </ColumnsContainer>
  );
}
