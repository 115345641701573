import { useUpdateMessageTemplate } from '@app/api/message-template.api';
import MessagingForm, { TMessageTemplateForm } from '@app/components/apps/messaging/MessagingForm';
import useMessagingForm from '@app/components/apps/messaging/useMessagingForm';
import ComponentHeader from '@app/components/common/ComponentHeader/ComponentHeader';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { DataTable } from '@app/components/tables/AntdTableWrapper';
import { useDataTable } from '@app/components/tables/AntdTableWrapper/hooks/useDataTable';
import { TActionItems } from '@app/components/tables/AntdTableWrapper/hooks/useTableFilters';
import { TDataColumns } from '@app/components/tables/AntdTableWrapper/types';
import { getEnumValue } from '@app/services/enum.service';
import { TMessageTemplate } from '@app/types/messaging';
import { useState } from 'react';

const tableColumns: TDataColumns<TMessageTemplate> = [
  {
    title: 'ID',
    dataIndex: 'id',
    allowFiltering: true,
    type: 'number',
    width: '2vw',
  },
  {
    title: 'Last Updated',
    dataIndex: 'lastUpdateTime',
    allowFiltering: true,
    type: 'date',
    width: '4vw',
  },
  {
    title: 'Name',
    dataIndex: 'displayName',
    allowFiltering: true,
    type: 'text',
    width: '4vw',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    allowFiltering: true,
    type: 'text',
    width: '10vw',
  },
];

const MessagingPage = () => {
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<TMessageTemplate | undefined>(undefined);
  const { mutateAsync: updateMessageTemplate, isLoading } = useUpdateMessageTemplate();
  const { toggle, ...messagingFormProps } = useMessagingForm({
    onOk: async ({ id, title, content }: TMessageTemplateForm) => {
      await updateMessageTemplate(
        {
          id: Number(id),
          payload: {
            title,
            content,
          },
        },
        {
          onSuccess: () => {
            setSelectedMessageTemplate(undefined);
            toggle();
            dataTableProps.refetch();
          },
        },
      );
    },
    onCancel: () => {
      setSelectedMessageTemplate(undefined);
      toggle();
    },
    messageTemplate: selectedMessageTemplate,
  });

  const actionItems: TActionItems<TMessageTemplate>[] = [
    {
      key: 'edit',
      label: 'Edit',
      multiSelect: false,
      onClick: (record) => {
        setSelectedMessageTemplate(record);
        toggle();
      },
    },
  ];

  const dataTableProps = useDataTable<TMessageTemplate>({
    model: 'MessageTemplate',
    columns: tableColumns,
    searchableColumns: ['displayName'],
    actionItems,
    constantFilter: `status eq ${getEnumValue('MessageTemplateStatus', 'Enabled')}`,
    defaultOrder: {
      column: 'displayName',
      order: 'asc',
    },
  });

  return (
    <>
      <PageTitle>Messaging</PageTitle>
      <ComponentHeader title="Messaging" subTitle="Edit templates for emails sent by the system" />
      <DataTable {...dataTableProps} />
      <MessagingForm {...messagingFormProps} isLoading={isLoading} />
    </>
  );
};

export default MessagingPage;
