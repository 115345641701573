import { ErrorMessage } from '@app/components/auth/LoginForm/LoginForm.styles';
import { HorizontalAlignedContainer } from '@app/components/common/BaseLayout/BaseLayout.styled';
import { Control, Controller, FieldErrors, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import { TRecommendationForm } from '../RecommendationForm';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { Select } from 'antd';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #272727;
  flex-basis: 35%;
  flex-shrink: 0;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FieldArea = styled.textarea`
  flex-basis: 75%;
  background-color: transparent;
  border: 1px solid #acacac;
  border-radius: 10px;
  padding: 12px;
  outline: none;
  resize: none;
`;

type TOption = {
  value: number;
  label: string;
};

type TOptions = {
  value: string;
  label: string;
};

interface RequirementsFieldsProps {
  control: Control<TRecommendationForm>;
  errors: FieldErrors<TRecommendationForm>;
  setValue: UseFormSetValue<TRecommendationForm>;
  licenseOptions: TOption[];
  prerequisiteLoading: boolean;
  prerequisiteOptions: TOptions[];
}

export default function RequirementsFields({
  control,
  errors,
  setValue,
  licenseOptions,
  prerequisiteLoading,
  prerequisiteOptions,
}: RequirementsFieldsProps) {
  return (
    <FormContainer>
      <HorizontalAlignedContainer>
        <FieldLabel>Prerequisite Description:</FieldLabel>
        <Controller
          control={control}
          name="prerequisiteDescription"
          render={({ field }) => (
            <HtmlEditor
              initialValue={field.value}
              onChange={(content) => setValue('prerequisiteDescription', content)}
            />
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Prerequisite Reference:</FieldLabel>
        <InputContainer>
          <Controller control={control} name="prerequisiteReference" render={({ field }) => <FieldArea {...field} />} />
          {!!errors.prerequisiteReference && <ErrorMessage>This field is required</ErrorMessage>}
        </InputContainer>
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Licenses:</FieldLabel>
        <Controller
          control={control}
          name="licenses"
          render={({ field }) => (
            <Select
              {...field}
              value={field.value}
              showSearch
              allowClear
              mode="multiple"
              style={{ flexBasis: '75%' }}
              options={licenseOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {licenseOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>

      <HorizontalAlignedContainer>
        <FieldLabel>Prerequisites:</FieldLabel>
        <Controller
          control={control}
          name="prerequisites"
          render={({ field }) => (
            <Select
              {...field}
              mode="multiple"
              loading={prerequisiteLoading}
              allowClear
              value={field.value}
              showSearch
              style={{ flexBasis: '75%' }}
              options={prerequisiteOptions}
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
              }
            >
              {prerequisiteOptions.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                  {opt.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </HorizontalAlignedContainer>
    </FormContainer>
  );
}
