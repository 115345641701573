import Modal, { ModalFuncProps } from 'antd/lib/modal/Modal';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useSetCompliantRecommendation } from '@app/api/recommendation.api';
import { TAccountRecommendationExtended } from '@app/types/accountRecommendationExtended';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import HtmlEditor from '@app/components/common/HTMLEditor/htmlEditor';
import { useGetManyRecommendationExtended } from './hooks/useGetRecommendationExtended';
import { Spin } from 'antd';

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalMessage = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: #272727;
`;

const FieldLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #272727;
`;

const ApplyButton = styled.button`
  background-color: #3a50d1;
  color: white;
  padding: 8px 60px;
  border: 1px solid #3a50d1;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: default;
  }
`;
const CancelButton = styled.button`
  background-color: #fff;
  color: #272727;
  padding: 8px 48px;
  border: 1px solid #272727;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
`;

const InputContainer = styled.div`
  flex-basis: 75%;
  display: flex;
  flex-direction: column;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 40px 0px;
  gap: 20px;
`;

const HorizontalAlignedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export type TAssignRecommendationForm = {
  notes?: string;
};

interface IMarkComplianceModalProps extends ModalFuncProps {
  recommendationIds: number[];
  recommendations?: TAccountRecommendationExtended[];
  complianceAction: 'mark-compliant' | 'mark-non-compliant';
}

export default function MarkComplianceModal({
  complianceAction,
  recommendationIds,
  recommendations,
  ...modalProps
}: IMarkComplianceModalProps) {
  const { recommendations: fetchedRecommendations, isLoading: isRecommendationsLoading } =
    useGetManyRecommendationExtended({
      recommendationIds,
      recommendationsProp: recommendations,
    });

  const { mutateAsync: setCompliantRecommendation, isLoading } = useSetCompliantRecommendation();

  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<TAssignRecommendationForm>({
    mode: 'onBlur',
  });

  // const isCompliantRecommendations = useMemo(() => {
  //   if ((fetchedRecommendations.at(0)?.isCompliant as string)?.length === 0) {
  //     return complianceAction === 'mark-compliant' ? false : true;
  //   }

  //   return fetchedRecommendations.at(0)?.isCompliant;
  // }, [modalProps.open, fetchedRecommendations]);

  const complianceTitle = useMemo(() => {
    return complianceAction === 'mark-compliant' ? 'Compliant' : 'Non-Compliant';
  }, [modalProps.open, recommendations]);

  const handleAssign: SubmitHandler<TAssignRecommendationForm> = async (data) => {
    console.log(fetchedRecommendations);
    const payload = {
      accountRecommendationIds: fetchedRecommendations.map((d) => d.id),
      compliant: complianceAction === 'mark-compliant' ? true : false,
      notes: data.notes,
    };
    const response = await setCompliantRecommendation(payload);

    if (response.status === 201) {
      reset();
      modalProps.onOk?.();
    }
  };

  const handleCloseModal = () => {
    reset();
    modalProps.onCancel?.();
  };

  return (
    <Modal
      {...modalProps}
      style={{
        minWidth: '600px',
      }}
      title={`Mark Recommendations as ${complianceTitle}`}
      destroyOnClose={true}
      onCancel={handleCloseModal}
      footer={null}
    >
      {isRecommendationsLoading ? (
        <Spin />
      ) : (
        <>
          <FieldContainer>
            <ModalMessage>
              {`Are you sure you want to set these recommendations as ${complianceTitle.toLowerCase()}?`}
            </ModalMessage>
          </FieldContainer>

          <FormContainer onSubmit={handleSubmit(handleAssign)}>
            <FieldContainer>
              <FieldLabel>Notes:</FieldLabel>
              <InputContainer>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field }) => (
                    <HtmlEditor initialValue={field.value || ''} onChange={(content) => setValue('notes', content)} />
                  )}
                />
              </InputContainer>
            </FieldContainer>
            <HorizontalAlignedContainer
              style={{
                gap: '10px',
                justifyContent: 'center',
                marginTop: '16px',
              }}
            >
              <CancelButton type="button" onClick={modalProps.onCancel}>
                Cancel
              </CancelButton>
              <ApplyButton type="submit" disabled={isLoading}>
                Yes
              </ApplyButton>
            </HorizontalAlignedContainer>
          </FormContainer>
        </>
      )}
    </Modal>
  );
}
